header {
    overflow: hidden;
    padding-top: 20px;
    font-size: 15px;
    position: relative;
    background: transparent;
    overflow: hidden;
}

header nav {
    height: 100%;
}

header nav li {
    float: left;
    list-style: none;
    margin-right: 23px;
}

header nav li a,
header a.button-mail {
    display: block;
    text-decoration: none;
    letter-spacing: .02em;
    cursor: pointer;
    transition: .3s ease;
}

header nav li a:hover {
    color: #d91c29 !important;
    transition: .3s ease;
}

header nav li a.active {
    color: #d91c29;
}

.header-contact {
    float: right;
    margin-bottom: 50px;
}

.header-contact-items {
    width: 310px;
    position: relative;
}

.header-contact-items .button-phone {
    position: absolute;
    left: -25px;
}
.header-contact-items .phone-container {
    position: relative;
    display: inline-block;
    margin-left: 50px;
    float: right;
}
.header-contact-items .button-city {
    position: absolute;
    left: -25px;
}

/* .header-contact-items .button-phone span {
    display: block;
    width: 40px;
    height: 1px;
    background: #abb1ba;
    position: absolute;
    top: 9px;
    right: -55px;
} */

/*
.index-components .Header-contact-items .phone-icon span{
  background: #fff;
}
.index-components .Header-contact-items .phone-icon svg path{
  fill:#fff;
}
*/
.header-contact-items > p {
    font-size: 13px;
    line-height: 23px;
    letter-spacing: .02em;
}

.header-contact-items > p a {
    font-weight: 600;
    font-size: 15px;
}

h1.h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0;
    min-height: 40px;
}

.page-title,
.block-design-title {
    padding-left: 40px;
    padding-right: 40px;
}

.page-title > span {
    display: block;
    padding-bottom: 10px;
    font-size: 11px;
    color: #8e969e;
    text-transform: uppercase;
    letter-spacing: .3em;
}

.button-mail:before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    margin-bottom: -1px;
    width: 23px;
    height: 14px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='23px' height='14px'><path fill-rule='evenodd' fill='rgb(0, 0, 0)' d='M19.750,13.999 L0.750,13.999 L3.021,-0.001 L22.021,-0.001 L19.750,13.999 ZM18.894,12.999 L20.479,3.094 L11.063,9.020 L3.527,2.987 L1.925,12.999 L18.894,12.999 ZM3.845,0.999 L3.724,1.757 L11.245,7.832 L20.691,1.769 L20.814,0.999 L3.845,0.999 Z'/></svg>");
    transition: .3s ease;

}

.button-mail:hover:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='23px' height='14px'><path fill-rule='evenodd' fill='rgb(217, 28, 41)' d='M19.750,13.999 L0.750,13.999 L3.021,-0.001 L22.021,-0.001 L19.750,13.999 ZM18.894,12.999 L20.479,3.094 L11.063,9.020 L3.527,2.987 L1.925,12.999 L18.894,12.999 ZM3.845,0.999 L3.724,1.757 L11.245,7.832 L20.691,1.769 L20.814,0.999 L3.845,0.999 Z'/></svg>");
    transition: .3s ease;
    -webkit-filter: unset !important;
    filter: unset !important;
}

.button-mail.white:before {
    filter: invert(100%);
}

.button-mail.gray:before {
    filter: invert(50%);
}

.button-city:before {
    display: inline-block;
    content: '';
    width: 17px;
    height: 19px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17px' height='19px' ><path fill='rgb(0, 0, 0)' d='M6,7.6c-1,0-1.8-0.8-1.8-1.8S5,4,6,4s1.8,0.8,1.8,1.8S7,7.6,6,7.6 M6,3C4.4,3,3.1,4.3,3.1,5.8S4.4,8.6,6,8.6  s2.9-1.3,2.9-2.8S7.6,3,6,3 M6,14.6c-0.5-0.5-1.4-1.5-2.1-2.5C2.1,9.7,1.3,7.6,1.3,6C1.3,1.4,5,1.1,6,1.1c4.4,0,4.7,3.8,4.7,4.9  C10.7,9.2,7.4,13.2,6,14.6 M10.1,1.4C9,0.5,7.6,0,6,0S3,0.5,1.9,1.4C0.7,2.5,0,4.1,0,6.1c0,4.4,5.3,9.5,5.5,9.7  C5.6,15.9,5.8,16,6,16s0.4-0.1,0.5-0.2c0.2-0.2,5.5-5.3,5.5-9.7C12,4.1,11.3,2.5,10.1,1.4'/></svg>");
    background-repeat: no-repeat;
    background-position: center 2px;
}

.button-city.white:before {
    filter: invert(100%);
}

.button-phone:before {
    display: inline-block;
    content: '';
    width: 17px;
    height: 19px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17px' height='19px'><path fill='rgb(0, 0, 0)' d='M14.699,8.872c-0.824,1.916-3.196,5.048-4.768,6.355l-3.055,2.876c-0.435,0.403-0.986,0.705-1.538,0.83c-0.275,0.063-0.556,0.101-0.816,0.084l-1.279-0.066c-1.042-0.043-1.931-0.63-2.345-1.527l-0.787-1.676c-0.261-0.551-0.049-1.233,0.492-1.609l3.488-2.391c0.448-0.305,1.014-0.33,1.419-0.041l1.897,1.297c1.303-1.372,3.175-3.551,4.144-5.655L9.77,6.127C9.364,5.838,9.186,5.319,9.344,4.801l1.183-3.824c0.193-0.628,0.831-1.052,1.466-0.966l1.744,0.24c0.87,0.108,1.59,0.605,2.009,1.349L16.3,2.566c0.45,0.787,0.489,1.769,0.102,2.669L14.699,8.872z M15.339,3.089l-0.555-0.967c-0.278-0.496-0.766-0.817-1.346-0.889l-1.745-0.24c-0.024,0.006-0.054-0.013-0.078-0.007c-0.075,0.017-0.169,0.089-0.184,0.168L10.25,4.977c-0.039,0.085,0.003,0.202,0.086,0.235l2.076,1.46c0.176,0.138,0.262,0.372,0.161,0.573l-0.021,0.03c-0.997,2.501-2.811,4.924-4.848,6.703c-0.183,0.169-0.462,0.207-0.638,0.068l-2.241-1.524c-0.086-0.057-0.189-0.059-0.258,0.007L1.079,14.92c-0.093,0.072-0.15,0.212-0.086,0.299l0.787,1.676c0.293,0.595,0.869,0.998,1.571,1.016l1.279,0.066C5.204,18,5.769,17.795,6.2,17.367l3.059-2.852c1.635-1.4,3.736-3.998,4.51-5.992l1.619-3.669C15.643,4.29,15.621,3.608,15.339,3.089z'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
}

.button-phone.white:before {
    filter: invert(100%);
}

.button-close {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'><path fill='rgb(50, 50, 50)' d='M0,1.415L1.415,0l22.577,22.578l-1.414,1.414L0,1.415z'/><path fill='rgb(50, 50, 50)' d='M23.992,1.415L22.577,0L0,22.578l1.415,1.414L23.992,1.415z'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}