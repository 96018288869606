footer {
  /*display: flex;*/
  /*flex: 0 0 100%;*/
  /*width: 100%;*/
  /*max-width: 73%;*/
  /*position: absolute;*/
  /*left: calc(20% + 40px);*/
  /*bottom: 0;*/
  padding: 25px 20px;
  border-top: 1px solid #c9cdd3;
}

footer .row .col-4 > :last-child {
  position: absolute;
  bottom: 0;
}

footer .row .col-4 .footer-biz {
  bottom: 35px;
}

footer .footer-mobil.row {
  display: none;
}

.footer-p {
  color: #788390;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.2em;
  padding-bottom: 80px;
}

.footer-p span {
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #000;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.footer-link {
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;
}

.footer-link.button-mail {
  bottom: 45px !important;
}

.footer-biz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-coo,
.footer-biz span {
  color: #788390;
  letter-spacing: 0.05em;
  font-size: 12px;
}

.footer-biz span {
  display: inline-block;
  text-align: right;
  vertical-align: bottom;
}

.footer-biz-logo {
  display: inline-block;
  padding-left: 20px;

  font-size: 16px;
  line-height: 1.4;
  text-align: center;
}

.footer-biz-logo:hover {
  opacity: 0.7;
}

.logo-text {
  text-align: center;
}
