@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("../fonts/Gilroy-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("../fonts/Gilroy-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("../fonts/Gilroy-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url("../fonts/Gilroy-ExtraboldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
}

*:focus {
  outline: 0;
}

p,
span,
a,
h1,
h2,
h3,
h4 {
  color: #010101;
  letter-spacing: 0.02em;
}

body {
  font-family: "Gilroy";
  background: #fff;
  position: relative;
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: scroll;
  position: fixed;
  height: 100%;
  width: 100%;
}

.slide-text .item.item-col-4 {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  height: calc(100vh - 197px);
  overflow: hidden;
}

.col-xs-1-5 {
  position: relative;
  min-height: 1px;
}

.col-xs-1-5 {
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.col-xs-1-4 {
  -webkit-flex: 0 0 80%;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
  overflow: visible;
  position: static;
  /*border: solid 1px red;*/
}

.slide-text .col-xs-1-4 {
  position: relative;
  height: calc(100vh - 197px);
}

.catalog-block .col-3 {
  padding-bottom: 20px;
  position: relative;
}

.catalog-block .col-3 .good-text-left p,
.catalog-block .col-3 .good-text-left span {
  transition: 0.3s ease;
}

.catalog-block .col-3:hover .good-text-left p,
.catalog-block .col-3:hover .good-text-left span {
  color: #d91c29;
  transition: 0.3s ease;
}

/*/////////////////////////////*/
.pdp-wr {
  max-width: 840px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
}

.pdp-content {
  width: 100%;
  overflow: hidden;
}

.pdp-wr p {
  text-indent: 20px;
}

.mobile-header {
  display: none;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: calc(100% - 60px);
  top: 60px;
  left: -100%;
  background: #fff;
  overflow-y: auto;
  transition: 0.3s all ease-in-out;
  z-index: 10;
  visibility: hidden;
}

.mobile-menu.active {
  visibility: visible;
  left: 0;
}

.mobile-menu .row {
  padding-bottom: 40px;
}

.mobile-menu-one {
  padding-top: 20px;
  padding-left: 40px;
}

.mobile-menu-two {
  padding-top: 60px;
  background: #f2f3f5;
  padding-left: 40px;
  overflow: hidden;
}

.mobile-menu-two li {
  list-style: none;
  display: block;
}

.mobile-menu-two li a {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  letter-spacing: 0.02em;
  position: relative;
}

.mobile-menu-two li a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -70px;
  width: 30px;
  height: 40px;
  background: url(../img/svg/menu-arrow2.svg) no-repeat right center;
  transition: 0.2s all ease-in-out;
}

.feedback {
  display: flex;
  align-items: baseline;

  width: 120%;
  margin-bottom: 40px;
  padding-top: 10px;
}
.feedback .button-mail {
  margin-right: 55px;
  padding-left: 30px;
  position: relative;
}
.feedback .button-mail:before {
  position: absolute;
  top: 0;
  left: 0px;
}
.feedback .button-phone {
  position: relative;
}
.feedback .button-phone:before {
  position: absolute;
  top: -3px;
  left: -26px;
}
.feedback__title {
  font-size: 20px;
  margin-bottom: 10px;
}
.main_feedback {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.main_feedback__title {
  text-align: center;
}

.mobile-menu-two li .price-list-link,
.mobile-menu-two li .remainders-link {
  position: static;
  color: #788390;
  font-weight: bold;
}

.mobile-menu-two li .price-list-link span,
.mobile-menu-two li .remainders-link span {
  color: red;
}

.mobile-menu-two__price-item {
  margin-top: 35px;
}

.mobile-menu-two ul {
  display: inline-block;
}

.mobile-menu-two li a.active {
  color: #d91c29;
  font-weight: 600;
}

.mobile-menu-two li a.active::before {
  left: -40px;
}

.mobile-menu-one li {
  list-style: none;
}

.mobile-menu-one li a {
  color: #000000;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  position: relative;
}

.mobile-menu-one > p {
  font-size: 12px;
  color: #788390;
  line-height: 40px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

.mobile-menu-one ul {
  display: inline-block;
}

.mobile-menu-one li a::before {
  background: url(../img/svg/menu-arrow2.svg) no-repeat right center;
  content: "";
  height: 40px;
  width: 30px;
  position: absolute;
  left: -70px;
  bottom: 1px;
  transition: 0.2s all ease-in-out;
}

.mobile-menu-one li a.active {
  color: #d91c29;
}

.mobile-menu-one li a.active:before {
  left: -40px;
}

.blocks > .item.aside {
  overflow: visible;
}

.mobile-menu-one > a {
  display: block;
  width: 100%;
  color: #788390;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
}

.mobile-menu-one > a span {
  color: #d91c29;
}

.mobile-menu-one > a:hover {
  color: #d91c29;
}

aside {
  height: calc(100vh - 90px);
}

aside.aside-fixed {
  position: fixed;
  height: 100vh;
  top: 0;
}

aside.aside-fixed .logo {
  margin-top: 40px;
}

aside > p {
  font-size: 12px;
  color: #788390;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 20px;
  margin-bottom: 15px;
}

aside > a {
  position: fixed;
  bottom: 70px;
  left: 40px;
  color: #788390;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
}

aside > a:hover {
  color: #d91c29;
}

aside > a span {
  color: #d91c29;
}

aside ul li {
  height: 40px;
  display: block;
  list-style: none;
}

aside ul li a {
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  transition: 0.2s all ease-in-out;
}

/* BOTTOM SELECTOR: ORDER IS IMPORTANT */
aside ul li a.active::before {
  bottom: 1px;
  left: calc(-100% - 70px);
}

aside ul li a::before,
aside ul li a.pseudo-before::before {
  background: url(../img/svg/menu-arrow2.svg) no-repeat right center;
  content: "";
  height: 40px;
  width: calc(100% + 60px);
  position: absolute;
  left: calc(-100% - 100px);
  bottom: -10px;
  transition: 0.3s all ease-in-out;
}

/* TOP SELECTOR: ORDER IS IMPORTANT */

aside ul li a.active,
aside ul li a:hover {
  color: #d91c29;
}

aside ul li a:not(.active):hover::before {
  left: -40px;
  bottom: -10px;
}

.logo {
  margin: 7px 0 40px 0;
}

.mobile-menu-two-mess {
  display: none;
  margin-top: 20px;
}

.favorites-fix {
  width: 60px;
  height: 60px;
  background: rgba(109, 122, 134, 0.9);
  position: fixed;
  right: 0;
  bottom: 200px;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  z-index: 15;
}

.favorits-fix-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.1s all ease-in-out;
}

.favorits-fix-icon svg {
  width: 20px;
  height: 20px;
}

.favorites-fix .favorits-fix-icon svg path {
  transition: 0.1s all ease-in-out;
}

.favorits-fix-num {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  transition: 0.1s all ease-in-out;
}

.favorits-fix-p {
  font-size: 11px;
  color: #fff;
  bottom: 10px;
  width: 100%;
  text-align: center;
  position: absolute;
  opacity: 0;
  transition: 0.1s all ease-in-out;
}

.favorites-fix:hover {
  width: 90px;
  height: 90px;
}

.favorites-fix:hover .favorits-fix-icon {
  width: 30px;
  height: 30px;
}

.favorites-fix:hover .favorits-fix-icon svg {
  width: 30px;
  height: 30px;
}

.favorites-fix:hover .favorits-fix-icon svg path {
  transform: scale(1.5);
}

.favorites-fix:hover .favorits-fix-p {
  opacity: 1;
}

.favorites-fix:hover .favorits-fix-num {
  top: 18px;
  left: 18px;
}

.index-scroll {
  position: absolute;
  right: 120px;
  top: 77vh;
  z-index: 9;
  cursor: pointer;
  transition: 0.5s ease;
}

.index-scroll.hidden {
  top: 81vh;
  opacity: 0;
  transition: 0.5s ease;
}

.index-scroll p {
  font-size: 15px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
  float: left;
}

/* +Order important!*/
.item-c {
  opacity: 0;
  transform: scale(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), all 0.3s ease;
}

.item-c-loaded {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease, transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

/* -Order important!*/

.arrow-scroll {
  width: 32px;
  height: 30px;
  margin-top: 5px;
  float: left;
  overflow: hidden;
  background: url(../img/svg/arrow-new-collections.svg) repeat-y center;
}

.index-scroll.hovered .arrow-scroll {
  background-position: center 30px;
  transition: 0.4s ease;
}

.page-title {
  margin-bottom: 20px;
}

.page-description {
  font-family: Gilroy;
  letter-spacing: 0.26px;
  padding: 0 20px;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
}

.page-description .page-desc-header {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 15px;
}

.page-desc-header .bold {
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
}

.page-desc-header .bold:hover {
  color: #de001b;
  transition: color 0.3s ease;
}

.page-description .grow {
  height: 0;
  overflow: hidden;
  transition: height 0.7s ease;
}

.page-description .page-desc-body span {
  display: block;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 15px;
}

.filter-block {
  width: 100%;
  background: #edeef0;
  height: 120px;
  z-index: 10;
}

.filter-block.hide {
  height: auto;
}

.filter-block-wraper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.filter-block-item {
  flex-grow: 1;
  border-right: 1px solid #d3d7db;
  padding: 30px;
}

.filter-block-item.reset-filter .reset-filter-image {
  width: 25px;
  height: 25px;
  margin: auto;
  display: block;
  background-image: url(../img/svg/filter-reset.svg);
  transition: 0.3s ease;
}

.filter-block-item.reset-filter:hover .reset-filter-image {
  background-image: url(../img/svg/filter-reset-hover.svg);
  transition: 0.3s ease;
}

.filter-block-item:last-child {
  border-right: 0;
}

.rc-slider {
  width: 100%;
}

.result-filter-loader {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #d91c29;
  transition: width 0.3s ease, opacity 0.3s ease;
  height: 3px;
  width: 0;
}

.filter-block-price {
  padding: 30px;
}

.inputs-text {
  float: left;
  font-size: 12px;
  color: #242a2d;
  line-height: 25px;
  letter-spacing: 0;
}

.inputs-wr {
  float: right;
}

.inputs-wr input {
  float: left;
  width: 40px;
  height: 25px;
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 1px solid #cac9c9;
  font-size: 13px;
  -moz-appearance: textfield;
}

.inputs-wr input::-webkit-outer-spin-button,
.inputs-wr input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputs-wr span {
  float: left;
  font-size: 20px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.filter-price-inputs {
  margin-bottom: 13px;
  overflow: hidden;
}

.inputs-text span {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 13px;
}

.inputs-wr span.money-rus {
  font-size: 13px;
  line-height: 25px;
}

.rc-slider-step,
.rc-slider-track,
.rc-slider-rail {
  height: 3px;
}

.rc-slider-rail {
  background: #c6cbd0;
}

.rc-slider-track {
  background: #d91c29;
}

.rc-slider-handle {
  background: url(../img/png/str.png) no-repeat center;
  border: 0;
  width: 14px;
  height: 14px;
  border-radius: 0;
  margin-top: 0;
}

.filter-block-type p,
.filter-color-hide p,
.filter-tags-hide p,
.filter-block-composition p {
  font-size: 12px;
  text-transform: uppercase;
  color: #242a2d;
  line-height: 25px;
  letter-spacing: 0.2em;
  transition: 0.3s all ease-in-out;
}

.filter-tipe-hide p span,
.filter-color-hide p span,
.filter-tags-hide p span,
.filter-composition-hide p span {
  float: right;
  margin-top: 4px;
}

.filter-tipe-hide p span svg,
.filter-color-hide p span svg,
.filter-tags-hide p span svg,
.filter-composition-hide p span svg {
  width: 14px;
  height: 14px;
  display: block;
  transition: 0.3s all ease-in-out;
}

.list-span {
  color: #959da6;
  font-size: 12px;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  transition: 0.3s all ease-in-out;
  position: absolute;
  overflow: hidden;
  width: calc(100% - 60px);
  min-height: 25px;
}

.filter-block-item:hover .list-span span {
  background: #dcdfe3;
}

.list-span span {
  float: none !important;
  text-transform: initial;
  color: #959da6;
  font-size: 12px;
  padding-right: 10px;
  position: relative;
  z-index: 1;
  transition: 0.3s all ease-in-out;
  background: #edeef0;
}

.list-span span:first-child {
  padding-right: 30px;
}

.list-span span:nth-child(2) {
  margin-left: -20px;
}

.list-span::before {
  text-transform: initial;
  letter-spacing: 0;
  position: absolute;
  margin-top: 0 !important;
  color: #959da6;
  font-size: 12px;
  margin-top: 12px;
  content: "Любое";
}

.list-span span::after {
  content: ", ";
}

.list-span span:last-child::after {
  content: "";
}

.filter-block-tags,
.filter-block-type,
.filter-block-color,
.filter-block-composition {
  position: relative;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.filter-block-type:hover,
.filter-block-color:hover,
.filter-block-tags:hover,
.reset-filter:hover,
.filter-block-composition:hover {
  background: #dcdfe3;
}

.filter-block-type:hover .filter-tipe-hide p span svg path,
.filter-block-color:hover .filter-color-hide p span svg path,
.filter-block-tags:hover .filter-tags-hide p span svg path,
.reset-filter:hover svg path,
.filter-block-composition:hover .filter-composition-hide p span svg path {
  fill: #d9212d;
}

.filter-tipe-show,
.filter-color-show,
.filter-tags-show,
.filter-composition-show {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: calc(100% + 24px);
  background: #dcdfe3;
  z-index: 10;
  padding: 30px 30px 15px 30px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.filter-color-show {
  width: 340px;
}

.filter-tags-show {
  width: calc(100% + 60px);
}

.filter-block-item.is-active .opened-block {
  visibility: visible;
  opacity: 1;
}

.filter-tipe-show p,
.filter-color-show p,
.filter-tags-show p,
.filter-composition-show p {
  font-size: 12px;
  text-transform: uppercase;
  color: #242a2d;
  line-height: 25px;
  letter-spacing: 0.2em;
  transition: 0.3s all ease-in-out;
}

.filter-tipe-show > p > span,
.filter-color-show > p > span,
.filter-tags-show > p > span,
.filter-composition-show > p > span {
  float: right;
  margin-top: 4px;
}

.filter-tipe-show > p > span svg,
.filter-color-show > p > span svg,
.filter-tags-show > p > span svg,
.filter-composition-show > p > span svg {
  width: 14px;
  height: 14px;
  display: block;
  transition: 0.3s all ease-in-out;
}

.filter-tipe-checkbox,
.filter-tags-checkbox,
.filter-color-radio,
.filter-composition-checkbox {
  padding-top: 30px;
}

.filter-tipe-checkbox input,
.filter-tags-checkbox input,
.filter-color-radio input,
.filter-block-NewsOrRas input,
.filter-composition-checkbox input,
.filter-block-flammability input {
  display: none;
}

.filter-tipe-checkbox label,
.filter-tags-checkbox label,
.filter-color-radio label,
.filter-block-NewsOrRas label,
.filter-composition-checkbox label,
.filter-block-flammability label {
  display: flex;
  width: 100%;
  padding-bottom: 25px;
  cursor: pointer;
  align-items: center;
}

.send-message-form .checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}

.send-message-form .checkbox + label {
  cursor: pointer;
}

.send-message-form .checkbox:not(checked) {
  position: absolute;
  display: none;
}

.send-message-form .checkbox:not(checked) + label {
  position: relative;
  padding-left: 30px;
}

/* Оформление первой части чекбокса в выключенном состоянии (фон). */
.send-message-form .checkbox:not(checked) + label:before {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0;
  width: 20px;
  height: 20px;
  border: solid 1px gray;
}

.send-message-form .checkbox:checked + label:before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhGMUY2QkM0MEQ3NjExRTdCRDdBRDY4MDZCOEFGOTgyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhGMUY2QkM1MEQ3NjExRTdCRDdBRDY4MDZCOEFGOTgyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEYxRjZCQzIwRDc2MTFFN0JEN0FENjgwNkI4QUY5ODIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEYxRjZCQzMwRDc2MTFFN0JEN0FENjgwNkI4QUY5ODIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5iBB83AAAA1ElEQVR42mIQExP7z8DAAMZxcXGb////z4ANL1q0KJyVlfU/UH1rd/8kdobc3NwFMI1CQkL/sWkKDw/fA5L38fE5CxNjOHDggC5MIwhfvnxZBCZ59OhRdSkpqf+MjIz/J0yYUIBsGJjg5eWFa+zp6SkDiTU2NraD+AoKCv/Pnj0rg+4KMJGcnLwOphHkZ3l5eTA7Ojp6Jy4/g4nt27dbIzuXjY3t//LlywNxaYJrBGFOTk6wJltb2wf4NMAwI1g3EMyePTvp58+f3Dk5OZMZiAAAAQYAYS7kadIv7R4AAAAASUVORK5CYII=)
    #fff no-repeat right center;
}

/*.send-message-form #pdp-check {*/
/*display: none;*/
/*}*/

/*.send-message-form label {*/
/*display: block;*/
/*width: 100%;*/
/*cursor: pointer;*/
/*padding-bottom: 10px;*/
/*}*/

/*.send-message-form label span {*/
/*display: block;*/
/*width: 20px;*/
/*height: 20px;*/
/*border: 1px solid #b8bcc0;*/
/*background: #fff;*/
/*float: left;*/
/*margin-right: 8px;*/
/*}*/

.filter-block-NewsOrRas label,
.filter-block-flammability label {
  padding-bottom: 10px;
}

.filter-block-NewsOrRas label p,
.filter-block-flammability label p {
  font-size: 12px;
  color: #515151;
  line-height: 21px;
}

.filter-block-NewsOrRas label:last-child,
.filter-block-flammability label:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.filter-tipe-checkbox label span,
.filter-tags-checkbox label span,
.filter-color-radio label span,
.filter-block-NewsOrRas label span,
.filter-composition-checkbox label span,
.filter-block-flammability label span {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #b8bcc0;
  background: #fff;
  float: left;
  margin-right: 8px;
}

.filter-color-radio label {
  width: 50%;
  float: left;
}

.filter-color-radio label span.border {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  border: 3px solid;
  position: relative;
  z-index: 1;
}

.filter-color-radio label span.circle {
  border-radius: 50px;
  border: 0;
  position: absolute;
  opacity: 0;
}

.filter-color-radio label[for="color290"] span {
  background: url(../img/png/g.png) no-repeat center;
}

.filter-color-radio input:checked + label span.circle {
  opacity: 1;
}

.filter-color-radio input:checked + label[for="color290"] span {
  background: url(../img/png/g-a.png) no-repeat center;
}

.filter-tipe-checkbox label p,
.filter-tags-checkbox label p,
.filter-color-radio label p,
.filter-composition-checkbox label p {
  line-height: 1.25;
  width: calc(100% - 30px);
}

.filter-color-radio label p {
  font-size: 12px;
  color: #515151;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}

.filter-tipe-checkbox input:checked + label span,
.filter-tags-checkbox input:checked + label span,
.filter-block-NewsOrRas input:checked + label span,
.filter-block-flammability input:checked + label span,
.filter-composition-checkbox input:checked + label span {
  background: url(/img/svg/checkbox.svg) #fff no-repeat right center;
}

.reset-filter {
  max-width: 90px;
  min-width: 90px;
  padding: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.reset-filter img {
  width: 25px;
  height: 25px;
  margin: auto;
  display: block;
  transition: 0.3s all ease-in-out;
}

.reset-filter p {
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #515151;
  margin: auto;
  min-width: 100%;
  transition: 0.3s all ease-in-out;
}

.reset-filter:hover p {
  color: #000000;
}

.filter-block-NewsOrRas {
  min-width: 164px;
}

.filter-mobil-openFilter {
  float: right;
  width: 60px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  background: url(../img/svg/filter.svg) no-repeat center;
}

.filter-mobil-sort {
  height: 60px;
  line-height: 60px;
  float: left;
  padding-left: 40px;
}

.filter-mobil-sort p {
  font-size: 13px;
  letter-spacing: 0.05em;
}

.filter-mobil-sort span {
  margin-right: 40px;
}

.filter-mobil-sort a {
  font-size: 13px;
  color: #8e969e;
  padding-right: 30px;
}

.filter-mobil-sort a.active {
  color: #d91c29;
}

.filter-mobil-sort a.active.up {
  background: url(../img/svg/price1.svg) no-repeat right 15px center;
}

.filter-mobil-sort a.active.down {
  background: url(../img/svg/price2.svg) no-repeat right 15px center;
}

.filter-mobil-panel {
  overflow: hidden;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  display: none;
}

.sort-block {
  height: 60px;
  line-height: 60px;
  width: 100%;
  overflow: hidden;
  padding: 0 40px;
}

.sort-block.none {
  display: none;
}

.sort-block p {
  font-size: 13px;
  letter-spacing: 0.05em;
}

.sort-block p span {
  margin-right: 40px;
}

.sort-block a {
  font-size: 13px;
  color: #8e969e;
  margin-right: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.sort-block a:hover {
  color: #d91c29 !important;
}

.sort-block a.active {
  color: #d91c29;
}

.sort-block a.active.up {
  background: url(../img/svg/price1.svg) no-repeat right 5px center;
}

.sort-block a.active.down {
  background: url(../img/svg/price2.svg) no-repeat right 5px center;
}

.goods-wr {
  /* padding-bottom: 60px; */
}

.random-block {
  width: 100%;
  height: 100%;
  border: 3px;
}

.random-block {
  border-color: #ffe582;
}

.random-block-border {
  width: 100%;
  overflow: hidden;
  padding-top: 50%;
  padding-bottom: calc(50% - 3px);
  position: relative;
}

.random-block-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.random-block-item-text {
  display: flex;
  margin: auto;
  height: 100%;
}

.random-block-item-text div {
  margin: auto 0px;
  width: 100%;
}

.random-block-border.ellow {
  border: 3px solid #ffe582;
}

.random-block-border.blue {
  border: 3px solid #99eee9;
}

.random-block-border.green {
  border: 3px solid #bdecb7;
}

.random-block-item-text p.random-block-title {
  -webkit-appearance: none;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
}

.random-block-item-text p.random-block-title::after {
  -webkit-appearance: none;
  width: 50px;
  height: 1px;
  background: #000;
  content: "";
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.random-block-item-text p.random-block-text {
  -webkit-appearance: none;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}

.good-status {
  height: 25px;
  position: absolute;
  margin: auto;
  z-index: 2;
  left: -10px;
  top: 0;
  bottom: 0;
}

.good-status p {
  display: block;
  width: 82px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  color: #000;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.good-status p:before {
  position: absolute;
  left: 0;
  content: "";
  transform: skewX(-15deg);
  width: 100%;
  height: 100%;
  background-color: #1acbe4;
  z-index: -1;
}

.good-status p.sale {
  color: #fff;
}

.good-status p.sale:before {
  background-color: #d91c29;
}

.detail-page-top {
  max-width: 1260px;
  padding: 0 20px;
}

h1.colection-name {
  font-size: 52px;
  line-height: 52px;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 10px;
}

p.colection-articl {
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.1em;
}

.type-status {
  color: #6c7580;
  line-height: 40px;
  letter-spacing: 0.02em;
  font-size: 17px;
  font-weight: 600;
}

.type-status a {
  text-decoration: underline;
  margin-right: 38px;
}

.colection-title-price {
  width: 100%;
  overflow: hidden;
}

.colection-title-price p {
  float: left;
  position: relative;
  margin-top: 28px;
  margin-bottom: 15px;
}

.colection-title-price p.priceSale {
  font-size: 27px;
  color: #959da6;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-right: 20px;
}

.colection-title-price p.priceSale::before {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #d91c29;
  top: 8px;
  left: 0;
  content: "";
  display: block;
  transform: rotate(-3deg);
}

.colection-title-price p.price {
  font-size: 27px;
  color: #d91c29;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.about-price {
  color: #788390;
  font-size: 15px;
  max-width: 300px;
  margin-top: 5px;
}

.colection-parametr-item {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.colection-parametr-item .col-6 {
  padding: 0;
}

.colection-parametr-item .col-6 p {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;
}

.colection-parametr-item .col-6:nth-of-type(1) {
  padding-right: 20px;
}

.colection-parametr-item .col-6:nth-of-type(1) p {
  color: #515151;
}

.colection-parametr-item .col-6:nth-of-type(2) {
  padding-left: 20px;
}

.colection-parametr-item .col-6:nth-of-type(2) p {
  color: #000;
  font-weight: 600;
}

.colection-parametr {
  padding-top: 30px;
  padding-bottom: 30px;
}

.colection-description {
  overflow: hidden;
  margin-bottom: 30px;
}

.colection-description > p {
  font-size: 17px;
  color: #000;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 75px;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}

.colection-description > p.active {
  height: 100%;
}

.collection-props {
  padding-top: 30px;
  position: relative;
}

.collection-props .col-6 {
  padding: 15px 0;
}

.collection-props p {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 40px);
  padding-left: 15px;
  font-size: 15px;
}
.collection-description {
  width: 100%;
  margin-top: 40px;

  line-height: 1.4;
}
.collection-description p {
  font-size: 17px;
  width: 100%;
  letter-spacing: 0.9;
  padding-left: 0;
}

.collection-props .collection-prop-header.description {
  cursor: pointer;
}

.collection-props .collection-prop-header.description span {
  transition: 0.2s all ease-in-out;
  border-bottom: 1px dashed #d91c29;
}

.collection-props .prop-descr-modal {
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 3px #edeef0;
  padding: 0 20px;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.collection-props .prop-descr-modal.at-top {
  bottom: 100%;
}

.collection-props .prop-descr-modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.collection-props .prop-descr-modal .prop-modal-header {
  position: relative;
  margin-top: 30px;
  padding-right: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.collection-props .prop-descr-modal .prop-modal-header h4 {
  font-style: normal;
  line-height: 1.24;
  font-weight: 600;
  padding-left: 10px;
}

.collection-props .prop-descr-modal p {
  margin-bottom: 30px;
  width: auto;
  padding-left: 0;
}

.collection-props .prop-descr-modal .close {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background-image: url(../img/svg/btn-close.svg);
}

.more-text {
  overflow: hidden;
  border-bottom: 1px solid #c9cdd3;
  padding-top: 19px;
  padding-bottom: 19px;
  cursor: pointer;
  margin-top: 10px;
}

.more-text:hover span {
  background: url(/img/svg/+act.svg) no-repeat center;
}

.has-preload {
  transition: 0.2s;
}
.has-preload:not(.loaded) {
  opacity: 0;
  transform: scale(0.8);
}

.more-text p {
  float: left;
  font-size: 15px;
  color: #6c7580;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.more-text span {
  float: right;
  width: 20px;
  height: 20px;
  background: url(/img/svg/+.svg) no-repeat center;
}

.slider-top .item {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider-bottom {
  padding-top: 20px;
}

.slider-bottom .item {
  position: relative;
  width: 100px;
  cursor: pointer;
}

.slider-bottom .item.swiper-slide-active {
  cursor: auto;
}

.slider-bottom .item.swiper-slide-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid #000;
}

.slider-top .item img {
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  height: auto;
}

.slider-top {
  width: 100%;
}

.swiper-pagination-bullet {
  width: 100px;
  height: 100px;
  border-radius: 0;
}

.slider-icon {
  width: 100%;
  overflow: hidden;
  padding-top: 40px;
}

.slider-icon-item {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icon-item-img {
  float: left;
  padding-right: 20px;
}

.icon-item-text p {
  font-size: 13px;
  line-height: 40px;
  letter-spacing: 0;
  color: #515151;
}

.block-design {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.block-design-title {
  display: block;
  width: 100%;
  padding-bottom: 40px;
  overflow: hidden;
}

.block-design-title h4 {
  font-size: 27px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

/*---------------- NEW DESIGN-ITEM*/

.my-gallery img {
  width: 100%;
  height: auto;
}

.my-gallery figure .action-favorits {
  left: 20px;
  transition: 0.3s ease;
}

.my-gallery figure:hover .design-item-name {
  color: #d91c29;
}

.my-gallery figure:hover .action-favorits {
  transition: 0.3s ease;
  opacity: 1;
}

.my-gallery figure {
  display: block;
  width: 150px;
  margin-bottom: 24px;
}

.my-gallery figcaption {
  display: none;
}

.my-gallery .design-item-name {
  padding-top: 8px;
}

.my-gallery .design-item-remainder {
  padding-top: 8px;
}

/*----------*/

.design-item {
  width: 100%;
  padding-bottom: 40px;
}

.design-item .action-favorits {
  transition: 0.3s ease;
}

.design-item-pich {
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  position: relative;
}

.design-item:hover .action-favorits {
  opacity: 1;
  transition: 0.3s ease;
}

.design-item-pich img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.design-item-text {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.design-item-text p {
  display: block;
  width: 100%;
}

.slider-info-block {
  display: flex;
  justify-content: space-between;
  line-height: inherit;
}

.slider-info-block .design-item-remainder {
  line-height: inherit;
}

.design-item-name {
  color: #515151;
  text-align: left;
  letter-spacing: 0.02em;
  line-height: 24px;
  float: left;
  display: block;
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.design-item-remainder {
  color: #d91c29;
  display: block;
  font-size: 15px;
  line-height: 24px;
}

.design-item:hover .design-item-name {
  color: #d91c29;
}

.design-item-price {
  display: block;
  text-align: right;
  float: right;
  font-size: 13px;
  color: #d91c29;
  line-height: 24px;
  letter-spacing: 0.05em;
  transition: 0.3s all ease-in-out;
}

.design-item:hover .design-item-price {
  color: #d91c29;
}

.block-design-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-design-title-left {
  float: left;
}

.block-design-title-right {
  line-height: 1.4;
}

.block-design-title-text {
  float: right;
  width: 350px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;
}

.block-design-title-name {
  padding-left: 20px;

  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: black;
}

.block-design-description {
  width: 500px;
}

.block-design-title-status {
  padding-left: 20px;
  font-size: 13px;
  color: #959da6;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 0.1em;
}

.slider-bottom .swiper-wrapper {
  transform: translate3d(0px, 0px, 0px) !important;
}

.mb-like-slider .col-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex: inherit;
  max-width: inherit;
}

.goods-standard {
  width: 100%;
}

.sw-pag .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #9ba3ad;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.sw-pag .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #d91c29;
}

.sw-pag {
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: center;
}

.mb-like {
  background: #edeef0;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.mb-like-title {
  padding-top: 35px;
  margin-bottom: 45px;
}

.mb-like-title p {
  font-size: 32px;
  color: #000;
  line-height: 32px;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
}

.you-show {
  width: 100%;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 40px;
}

.you-show .row {
  padding-left: 0;
  padding-right: 0;
  margin-right: -20px;
  margin-left: -20px;
}

.you-show-title {
  padding-bottom: 30px;
}

.you-show-title p {
  font-size: 27px;
  line-height: 30px;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
}

.col-12-8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding-left: 5px;
  padding-right: 5px;
}

.you-show .item-pich {
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
}

.you-show .swiper-slide.item {
  max-width: 157px;
}

.you-show .item-pich img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.you-show .item-name {
  text-align: center;
  color: #515151;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.2em;
  padding-top: 10px;
  padding-bottom: 4px;
}

.you-show .item-price {
  text-align: center;
  font-size: 11px;
  color: #d91c29;
  line-height: 18px;
}

.design-item-picher {
  padding-left: 20px;
  padding-right: 20px;
}

.design-item-picher img {
  display: block;
  margin-bottom: 40px;
  margin-top: 40px;
}

.glue-name {
  font-size: 27px;
  color: #000;
  font-weight: 600;
  letter-spacing: 0;
}

.glue-item .basis {
  color: #6c7580;
  z-index: 15;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.glue-pich {
  width: 100%;
  overflow: hidden;
}

.glue-pich img {
  display: block;
  width: 100%;
  height: auto;
}

.glue-price {
  width: 100%;
  overflow: hidden;
}

.glue-price p {
  float: left;
  position: relative;
  margin-top: 28px;
  margin-bottom: 15px;
}

.glue-price p.priceSale {
  font-size: 21px;
  color: #959da6;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-right: 20px;
}

.glue-price p.priceSale::before {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #d91c29;
  top: 8px;
  left: 0;
  content: "";
  display: block;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.glue-price p.price {
  font-size: 21px;
  color: #d91c29;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.def-text {
  font-size: 13px;
  flex-shrink: 18px;
  color: #788390;
  letter-spacing: 0;
}

.glue-page .colection-description > p {
  height: 0;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  font-size: 15px;
}

.glue-page .colection-description > p.active {
  height: inherit;
  opacity: 1;
  padding-top: 20px;
  padding-bottom: 10px;
}

.glue-page .colection-description {
  margin-bottom: 15px;
  margin-top: -15px;
}

.glue-page .more-text {
  margin-top: 0;
}

.glue-parametr > p {
  font-size: 17px;
  line-height: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 15px;
}

.glue-parametr-item {
  width: 100%;
}

.glue-parametr-item p {
  display: inline-block;
  width: 49%;
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: top;
}

.glue-parametr-item p.type {
  width: 140px;
  font-size: 13px;
  color: #515151;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.glue-parametr-item p.value {
  font-size: 13px;
  color: #000;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-weight: 500;
}

.glue-item {
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #dce1e8;
}

.glue-item {
  padding-top: 30px;
}

.glue-item:nth-of-type(n + 2) {
  padding-top: 30px;
}

.glue-item .col-4 {
  padding-left: 0;
}

.glue-item .col-8 {
  padding-right: 0;
}

.glue-item .more-text p {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
}

.glue-item .more-text span {
  transition: all 0.3s ease;
  margin-right: 20px;
}

.glue-item .more-text:hover span {
  transition: all 0.3s ease;
}

.detail-page-mobil-title {
  display: none;
}

.slider-icon.slider-icon-mobil {
  display: none;
}

.breadcrumbs {
  display: none;
}

.breadcrumbs a {
  font-size: 11px;
  color: #788390 !important;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 40px;
}

.breadcrumbs a::after {
  content: ">";
  margin-left: 4px;
  margin-right: 4px;
  font-size: 11px;
  color: #788390;
}

.breadcrumbs a:last-child::after {
  content: "";
}

.filter-block-flammability {
  min-width: 120px;
}

.swiper-slide .col-3 {
  display: block;
  max-width: 100%;
}

.news-goods {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  background: #edeef0;
}

.more-text.active span {
  background: url(/img/svg/-.svg) no-repeat center;
}

.more-text.active:hover span {
  background: url(/img/svg/-act.svg) no-repeat center;
}

.favorites-top p {
  font-size: 17px;
  color: #010101;
  line-height: 26px;
  letter-spacing: 0;
  width: 50%;
}

.favorits-content {
  padding-top: 40px;
}

.favorites-item {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #dce1e8;
  border-bottom: 1px solid #dce1e8;
  transition: 0.3s all ease-in-out;
}

.favorites-item:last-child {
  border-bottom: 1px solid #fff;
}

.favorites-item:hover {
  border-bottom: 1px solid #6c7580;
}

.favorites-panel {
  cursor: pointer;
}

.favorites-panel-left {
  padding-left: 0;
  padding-top: 37px;
  padding-bottom: 37px;
}

.favorites-panel-right {
  padding-right: 0;
  padding-top: 37px;
  padding-bottom: 37px;
}

.favorites-panel-left .icon {
  width: 26px;
  height: 30px;
  background: url(/img/svg/+.svg) no-repeat center;
  float: left;
  margin-right: 6px;
  transition: 0.3s all ease-in-out;
}

.favorites-panel:hover .icon {
  background: url(/img/svg/+act.svg) no-repeat center;
}

.favorites-item.active .favorites-panel .icon {
  background: url(/img/svg/-.svg) no-repeat center;
}

.favorites-item.active .favorites-panel:hover .icon {
  background: url(/img/svg/-act.svg) no-repeat center;
}

.favorites-panel-title {
  float: left;
  font-size: 21px;
  color: #000;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.favorites-panel-category {
  margin-left: 15px;
  display: block;
  float: left;
  padding-top: 8px;
}

.favorites-panel-category span {
  font-size: 13px;
  color: #6c7580;
  line-height: 21px;
  letter-spacing: 0.02em;
  margin-right: 15px;
}

.button-delete {
  position: relative;
  float: right;
  padding-left: 25px;
  padding-right: 20px;
  font-size: 12px;
  line-height: 30px;
  color: #515151;
  background-position-x: 0;
  background-size: 13px;
}

.button-delete:before {
  position: absolute;
  content: "×";
  top: 2px;
  left: 5px;
  font: 23px Arial;
}

.button-delete:hover {
  color: black;
  transition: 0.3s all ease-in-out;
}

.button-delete:hover:before {
  color: red;
  transition: 0.3s all ease-in-out;
}

.favorites-list {
  height: 0;
  opacity: 0;
  padding-top: 0px;
  margin-right: -20px;
  margin-left: -20px;
  transition: opacity 0.25s ease-in-out;
}

.favorites-list .row {
  width: 100%;
}

.post-page,
.news-page {
  padding-bottom: 70px;
}

.post-page .main-post {
  margin-bottom: 40px;
}

.post-page-wr {
  padding-left: 20px;
  padding-right: 20px;
}

.favorites-item.active .favorites-list {
  height: unset;
  opacity: 1;
}

.favorites-list .col-3 {
  flex: 0 0 33.3%;
  max-width: 33.3%;
  margin-bottom: 20px;
}

/*.post-top{
	padding-left: 20px;
	padding-right: 20px;
}*/
.detail-page-post {
  max-width: 840px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
}

.detail-page-post-content {
  width: 100%;
  overflow: hidden;
}

.detail-page-post-pich {
  padding-top: 30px;
  padding-bottom: 20px;
}

.detail-page-post-pich img {
  display: block;
  width: 100%;
  height: auto;
}

.detail-page-post-pich p.alt {
  color: #788390;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0;
  margin-top: 25px;
}

.detail-page-post .description-text {
  font-size: 27px;
  color: #010101;
  letter-spacing: 0;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 60px;
}

.detail-text p {
  font-size: 21px;
  color: #010101;
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 35px;
}

.detail-text p a {
  text-decoration: underline;
}

.detail-text p a:hover {
  text-decoration: none;
}

.detail-text p.title {
  font-weight: 600;
  color: #010101;
  font-size: 32px;
}

.detail-text-img img {
  margin: auto;
  max-width: 500px;
  display: block;
}

.detail-text-img p.alt2 {
  max-width: 600px;
  display: block;
  margin: auto;
  text-align: center;
  color: #010101;
  font-size: 27px;
  line-height: 34px;
  letter-spacing: 0;
  margin-top: 60px;
  margin-bottom: 60px;
}

.detail-text-img {
  padding-top: 40px;
}

.detail-text p span {
  font-weight: 600;
}

.detail-text ul {
  padding: 0;
  margin: 0;
  padding-bottom: 30px;
}

.detail-text ul p {
  font-size: 27px;
  color: #010101;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.detail-text ul li {
  color: #010101;
  font-size: 21px;
  line-height: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  list-style: none;
  position: relative;
}

.detail-text ul li::before {
  width: 3px;
  height: 3px;
  display: block;
  float: left;
  margin-top: 16px;
  margin-right: 20px;
  border-radius: 50px;
  background: #010101;
  content: "";
}

.avtor {
  width: 100%;
  overflow: hidden;
  padding-top: 40px;
}

.avatar {
  width: 90px;
  height: 90px;
  display: block;
  float: left;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
}

.avatar img {
  height: 100%;
  display: block;
}

.avtor-text {
  float: left;
  padding-top: 11px;
}

.swiper-pagination-bullet {
  opacity: 1;
}

.avtor-name {
  color: #010101;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 5px;
}

.avtor-info {
  font-size: 17px;
  line-height: 26px;
  color: #788390;
}

.detail-page-soc {
  width: 100%;
  height: 50px;
  background: #edeef0;
  overflow: hidden;
  margin-top: 70px;

  display: none;
}

.detail-page-soc .item {
  width: 25%;
  height: 50px;
  border-right: 1px solid #d3d7db;
  float: left;
}

.detail-page-soc .item a {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  padding-top: 14px;
}

.detail-page-soc .item a svg path {
  transition: 0.3s;
}

.detail-page-soc .item a:hover svg path {
  fill: #5786b7;
}

.detail-page-soc .item a svg {
  height: 22px;
  display: block;
  margin: auto;
}

.detail-page-soc .item:last-child {
  border-right: 0;
}

.detail-page-mobil {
  display: none;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.detail-page-mobil a {
  float: left;
  font-size: 11px;
  text-transform: uppercase;
  color: #788390;
  letter-spacing: 0.2em;
}

.detail-page-mobil p {
  float: left;
  font-size: 11px;
  text-transform: uppercase;
  color: #d91c29;
  margin-left: 15px;
  letter-spacing: 0.2em;
}

.detail-page-mobil p::before {
  width: 30px;
  height: 1px;
  background: #d91c29;
  display: block;
  content: "";
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}

.opora .good-text-name {
  letter-spacing: 0.1em;
  text-transform: unset;
}

.accessories-pich {
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  background: #ccc;
  position: relative;
  background-size: cover;
}

/*.opora-img.accessories-pich:before {*/
/*content: "";*/
/*position: absolute;*/
/*top: 0;*/
/*left: 0;*/
/*width: 100%;*/
/*height: 100%;*/
/*background: rgba(0, 0, 0, 0.1);*/
/*}*/

.acce-page-top {
  width: 100%;
  overflow: hidden;
}

.acce-page-top-img {
  width: 320px;
  float: left;
  overflow: hidden;
}

.acce-page-top-img img {
  display: block;
  height: auto;
  width: 100%;
}

.aces-page-top-table {
  padding-top: 10px;
}

.aces-page-top-table table {
  width: 100%;
}

.aces-page-top-table tr {
  border-bottom: 1px solid #d3d7db;
}

.aces-page-top-table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.aces-page-top-table tr td {
  font-weight: 600;
}

.aces-page-top-table thead tr td {
  font-weight: normal;
  padding-right: 5px;
}

.acce-page-color {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
  overflow: hidden;
}

.acce-page-color .item {
  width: 90px;
  height: 90px;
  line-height: 90px;
  float: left;
  position: relative;
  text-align: center;
  float: left;
}

.acce-page-color.opora .item {
  width: 130px;
  height: auto;
  border: 1px solid #ccc;
  margin-left: -1px;
}

.acce-page-color.opora .item .item-img {
  width: 100%;
  height: 130px;
}

.acce-page-color.opora .item p {
  height: auto;
  line-height: 40px;
  border-top: 1px solid #ccc;
}

/*.acce-page-color .item:first-child {*/
/*color: #fff;*/
/*}*/

/*.components-contact-title{
	padding: 0 40px;
}*/
.page-contact-wr .item {
  position: relative;
  border: 4px solid #edeef0;
  background: #edeef0;
  cursor: pointer;
}

.page-contact-wr .item .item-ab p.item-name {
  transition: color 0.3s ease;
}

.page-contact-wr .item:hover .item-ab p.item-name {
  transition: color 0.3s ease;
  color: #d91c29;
}

.page-contact-wr .item-ab a.email:hover {
  color: #d91c29;
}

.page-contact-wr .item.active {
  background: #fff;
}

.page-contact-wr .item.active .item-ab p.item-name {
  color: #d91c29;
}

.page-contact-wr .item + .item {
  border-left: 1px solid #fff;
}

.page-contact-wr .item.active + .item {
  border-left: 1px solid #edeef0;
}

.page-contact-wr .item .item-ab {
  padding: 30px 20px;
  overflow: hidden;
}

.item-ab p.item-name {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.item-ab p.item-street {
  font-size: 15px;
  color: #000;
  line-height: 21px;
  margin-bottom: 20px;
  letter-spacing: 0.02em;
}

.item-ab p.item-phone {
  font-size: 15px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.02em;
  max-width: 140px;
  margin-bottom: 15px;
}

.item-ab a.email {
  text-decoration: underline;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.02em;
  transition: color 0.3s ease;
}

.map {
  width: 100%;
  height: 420px;
  overflow: hidden;
}

.map > iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.contact-form {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact-form-title p {
  font-size: 27px;
  font-weight: 600;
  line-height: 40px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.contact-form-tab {
  width: calc(100% - 50px);
  height: 50px;
  overflow: hidden;
  margin-top: 25px;
  cursor: pointer;
}

.contact-form-tab .item-tab {
  width: 33.3333%;
  float: left;
  padding: 3px;
  background: #edeef0;
  height: 47px;
  overflow: hidden;
}

.contact-form-tab .item-tab + .item-tab {
  border-left: 1px solid white;
}

.contact-form-tab .item-tab p {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 44px;
}

.contact-form-tab .item-tab.active {
  background: #fff;
  padding: 0;
  border: 3px solid #edeef0;
}
@media (min-width: 769px) {
  .contact-form-tab .item-tab.active + .item-tab {
    border-left: unset;
  }
}

.contact-form-wr {
  padding-top: 15px;
  padding-bottom: 5px;
  overflow: hidden;
}

.input-wr {
  position: relative;
  width: 50%;
  float: left;
  cursor: inherit;
}

.input-wr:last-child {
  width: 100%;
}

.input-wr.multiline {
  width: 100%;
}

.input-wr .input {
  width: calc(100% - 50px);
  border: 0;
  padding: 20px 0 0 0;
  margin-bottom: 15px;
  letter-spacing: 0.02em;
  background: unset;
  font-size: 21px;
  line-height: 30px;
  border-bottom: 1px solid #c9cdd3;
  z-index: 2;
}

.input-wr .input:focus {
  border-bottom: 1px solid #d91c29;
}

.input-wr.error .input {
  border-bottom: 1px solid #d91c29;
}

.input-wr span.error {
  position: absolute;
  left: 0;
  color: red;
  top: 26px;
  pointer-events: none;
}

.input-wr .input + .placeholder {
  position: absolute;
  left: 0;
  color: #939ca6;
  font-size: 15px;
  pointer-events: none;
}

.input-wr .input + .placeholder.empty {
  top: 26px;
  transition: 0.2s ease;
}

.input-wr .input:focus + .placeholder.empty {
  top: 0px;
  transition: 0.3s ease;
}

.contact-form-wr .arrow-button.no-action {
  pointer-events: none;
  opacity: 0.65 !important;
}

.contact-form-item {
  display: none;
}

.contact-form-item.active {
  display: block;
}

.mobil-map {
  display: none;
}

.goods-picher .action-favorits img {
  width: 40%;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.rc-slider-dot {
  display: none;
}

.catalog-page {
  position: relative;
  min-height: calc(100vh - 290px);
  padding-bottom: 40px;
}

.product-category-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
  min-height: 40px;
}

.loader {
  position: absolute;
  margin: auto;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% + 120px);
  height: calc(100% - 83px);
  background: rgba(255, 255, 255, 0.8);
  z-index: 3;
}

.loader > div {
  width: 64px;
  height: 64px;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
}

.not-found-text {
  text-align: center;
  padding-top: 40px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.posts {
  padding-top: 0;
}

.post-name {
  transition: color 0.3s ease;
}

.post-name:hover {
  transition: color 0.3s ease;
}

.logo.cursor-no a {
  cursor: context-menu;
}

.remainders-link {
  bottom: 40px;
}

.specify-link {
  position: fixed;
  bottom: 20px;
  font-size: 13px;
  color: #788390;
}

.specify-link_mobile {
  display: block;
  position: static;
  margin-top: -8px;
}

.attention-remainder {
  margin-top: 10px;
}
.news-goods-wrap {
  width: 100%;
  min-height: 350px;
  position: relative;
}

.slider-bottom .item img {
  width: 100%;
}

.page-contact-wr > .row {
  margin-bottom: 1px;
}

.item-picher {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.my-gallery .item-picher {
  position: unset;
}

.wrapper-404 {
  display: flex;
  height: 100%;
  position: fixed;
  width: 100%;
}

.block-404 {
  margin: auto;
}

.block-404 img {
  display: block;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  max-width: 680px;
}

.block-404 p.title-404 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.block-404 p {
  text-align: center;
  max-width: 680px;
  line-height: 25px;
}

.block-404 p a {
  text-decoration: underline;
  color: #d91c29;
  cursor: pointer;
}

.block-404 p a:hover {
  text-decoration: none;
}

.block-404 span {
  text-align: center;
  display: block;
  margin-top: 70px;
  color: #a0a0a0;
}

.popup-bg,
.popup-bg-phone {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
}

.popup-bg.active,
.popup-bg-phone.active {
  display: block;
}

.popup_small {
  max-height: 100px;
}

.popup-block {
  max-width: 921px;
  margin: auto;
  padding: 40px 20px 30px 60px;
  box-sizing: inherit;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 600px;
  display: none;
}

.popup-block_small {
  max-height: 200px;
}

.popup-block-phone {
  max-width: 320px;
  margin: auto;
  padding: 60px;
  padding-top: 40px;
  padding-bottom: 30px;
  box-sizing: inherit;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 250px;
  display: none;
}

.popup-block.active,
.popup-block-phone.active {
  display: block;
}

.popup-block .active {
  display: block;
}

.popup-block.message-active {
  display: block;
  max-height: 400px;
}

.popup-block.message-active .input-wr:nth-child(2) {
  margin-bottom: 25px;
}

.popup-block-title {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
}

.popup-block .button-close,
.popup-block-phone .button-close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 30px;
  cursor: pointer;
  transition: 0.2s all linear;
}

.popup-block .button-close:hover,
.popup-block-phone .button-close:hover {
  background-color: #f1f1f1;
}

.index-slider .slide {
  height: 85vh;
  background-attachment: fixed;
}
.header-contact-items p {
  text-decoration: underline;
  cursor: pointer;
}
.header-contact-items.popup-c {
  padding-bottom: 10px;
}

.header-contact-items.popup-c p span {
  display: block;
  text-align: left;
  float: none;
}

.goods-text {
  padding-left: 5px;
  padding-right: 5px;
}

.footer-p span a {
  display: block;
}

.footer-processing {
  color: #788390;
  position: absolute;
  bottom: 30px;
  font-size: 14px;
  width: 64%;
}

.link-processing {
  color: #788390;
  text-decoration: underline;
  transition: 0.3s ease;
}

.link-processing-error {
  text-decoration: underline red;
}

.link-processing:hover {
  color: #010101;
  transition: 0.3s ease;
}

.footer-processing a {
  color: #788390;
  text-decoration: underline;
  transition: 1s ease;
}

.footer-processing a:hover {
  transition: 0.4s ease;
  color: #010101;
}

.footer-mobil-items .span2 a {
  display: block;
}

.footer-mobil .footer-processing {
  position: unset;
  bottom: unset;
}

.animated-tile {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: -1;
}

.animated-tile.active {
  opacity: 0;
  width: 60px;
  height: 60px;
  background: rgba(109, 122, 134, 1);
  transition-property: top, left, width, height, opacity;
  transition-duration: 0.5s, 0.5s, 0.5s, 0.5s, 1s;
  transition-timing-function: ease-in-out;
}

.search-icon-wr {
  width: 50px;
  float: right;
}

.search-icon {
  display: table;
  margin: 0 auto;
  height: 20px;
  width: 20px;
  transition: 0.3s;
  background-size: contain;
  background: url(/img/svg/search_icon_black.svg) no-repeat left top;
}

.index-page .search-icon {
  background-image: url(/img/svg/search_icon.svg);
}

.search-icon:hover {
  background-image: url(/img/svg/search_icon_red.svg);
}

.search {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
}

.search.active {
  opacity: 1;
  pointer-events: all;
}

.search .close {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 5px;
  top: 5px;
  background-image: url(/static/media/btn-close.64170ea5.svg);
  background-size: cover;
}

.search input#search-open {
  display: none;
}

.search .search-field {
  max-width: 600px;
  width: 100%;
  height: 100px;
  margin-top: 100px;
}

.search .search-field input,
.search-on-results input {
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid #d91c29;
  color: #000;
  font-size: 40px;
  font-family: "Gilroy";
}

.search-on-results input {
  font-size: 32px;
  padding-bottom: 0;
}

.search span.highlight {
  color: #d91c29;
}

.search-suggests {
  overflow: auto;
  max-height: 60vh;
  position: relative;
}

.search-suggests a {
  display: block;
  margin-bottom: 10px;
}

.search-suggests .product-name {
  display: table;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.search-suggests .product-type {
  color: #000;
  display: table;
}

.search-suggests a:hover .product-name {
  color: #d91c29;
}

.search-on-results {
  width: 100%;
  height: 110px;
  background: #d3d7db;
  padding: 10px 15px 0 15px;
  margin-bottom: 30px;
}

.search-on-results .search-condition,
.search .condition {
  display: block;
  margin: 10px 0 30px 0;
  color: #8e969e;
}

.main-wrap {
  display: flex;
  flex-direction: column;
}

.main-wrap footer {
  margin-top: auto;
}
