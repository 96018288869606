.iframe {
  width: 100%;
  max-width: 960px;
  margin-top: 35px;
  height: 730px;
  border: none;
}

.iframe-wrapper {
  padding-left: 20px;
  max-width: 990px;
}

.iframe-description {
  font-family: Gilroy;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #020202;
}

.iframe-wrapper-mobile {
  display: none;
}

@media (max-width: 1120px) {
  .iframe-wrapper-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .iframe-wrapper-mobile a {
    color: #010101;
    font-family: Gilroy;
    font-weight: 600;
    cursor: pointer;
  }

  .iframe-wrapper-mobile .description {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .iframe-wrapper-mobile a:hover {
    color: #de001b;

  }
}