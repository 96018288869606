.index-page header * {
  color: #fff;
}

.index-page header .button-mail:before,
.index-page header .button-phone:before,
.index-page header .button-city:before {
  filter: invert(100%);
}

.index-page aside.active ul li a {
  color: #788390;
}

.index-page aside ul li a {
  color: #000;
}

.index-page aside ul li.active a {
  color: #d91c29;
}

.index-page aside ul li a:hover {
  color: #d91c29;
}

/* MySlider (using on home components) */
.myslider-container {
  position: absolute;
  width: 100%;
  height: 87vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.myslider-item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  background-size: cover;
  background-attachment: fixed;
  transform: scale(1.1);
  transition: transform ease-in-out 0.8s, opacity ease-in-out 0.9s;
}

.myslider-item.active {
  opacity: 1;
  transform: scale(1);
}

.myslider-shadow {
  background: rgba(11, 24, 38, 0.71);
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.myslider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-top: 225px;
  padding-left: 30px;
  color: white !important;
}

.myslider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-top: 225px;
  padding-left: 30px;
}

.myslider-text p {
  color: #fff;
}

.slide-title {
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  width: 670px;
  line-height: 52px;
  letter-spacing: 0;
}

.slide-description {
  margin-top: 40px;
}

.margin-after-slider {
  margin-top: calc(87vh - 99px);
}

.slide-date {
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}

.slide-date::before {
  width: 40px;
  height: 1px;
  background: #788390;
  display: block;
  content: "";
  float: left;
  margin-right: 20px;
  margin-top: 13px;
}

.slide-description-text {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
  font-weight: 700;
  width: 325px;
}

.goods-picher {
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  position: relative;
}

.goods-picher img {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: auto;
}

.goods-text {
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.good-text-left {
  flex: 0 0 50%;
  max-width: 50%;
}

.good-text-right {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}

.good-text-name {
  font-size: 13px;
  color: #000;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 500;
}

.good-text-name span {
  display: block;
  color: #000;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: lowercase;
}

.news-goods-title {
  padding: 60px 0;
  width: 100%;
  overflow: hidden;
}

.good-text-price {
  text-align: right;
  font-size: 13px;
  color: #d91c29;
  line-height: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.news-goods-title-p {
  float: left;
  overflow: hidden;
}

.news-goods-title-p p {
  font-size: 52px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
}

.news-goods-title-des {
  float: right;
  overflow: hidden;
  width: 690px;
  padding-right: 130px;
}

.news-goods-title-des p {
  font-size: 27px;
  line-height: 34px;
  letter-spacing: 0;
}

.action-favorits {
  width: 40px;
  height: 40px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(23, 36, 49, 0.6);
  cursor: pointer;
  opacity: 0;
}

.action-favorits svg {
  width: 20px;
  height: 20px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  right: 0;
  display: block;
  transition: 0.3s all ease-in-out;
}

.action-favorits:hover {
  background: rgba(23, 36, 49, 1);
}

.goods-standard .action-favorits {
  transition: 0.3s ease;
}

.goods-standard:hover .action-favorits {
  opacity: 1;
  transition: 0.3s ease;
}

.action-favorits.active {
  opacity: 1;
}

.goods-standard:hover .goods-name {
  color: #000;
}

/* ARROW ANIMATED BUTTON */
.arrow-button {
  position: relative;
  width: 248px;
  margin: 15px calc(50% - 124px);
  text-align: center;
  height: 66px;
  line-height: 66px;
  font-family: Gilroy;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.arrow-button:focus {
  outline: none;
}

/* ...border */
.arrow-button:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border: 3px solid #eceaeb;
  transition: all 0.3s ease-in-out;
}

.arrow-button:hover:before {
  transform: skewX(-15deg);
  border: 3px solid #d91c29;
  transition: all 0.3s ease-in-out;
}

/* ...arrow */
.arrow-button:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 10px;
  left: -25px;
  top: 28px;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='10' fill='#8c8c8c'><polygon points='0,5 50,5 50,6 0,6'/></svg>")
    no-repeat;
  transition: all 0.3s ease-in-out;
}

.arrow-button:hover:after {
  left: calc(100% - 25px);
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='10' fill='#8c8c8c'><polygon points='0,5 50,5 50,0 60,5 50,10 50,6 0,6'/></svg>");
  transition: all 0.3s ease-in-out;
}

.index-text {
  position: relative;
  overflow: visible;
  padding-top: 95px;
  padding-left: 20px;
  padding-right: 20px;
}

.index-text-wr {
  width: 700px;
  float: left;
}

.inner-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 auto !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.inner-sub-wrapper {
  width: 47%;
  max-width: 730px;
  margin-right: 15px;
}

.title-block {
  margin-bottom: 45px;
}

.index-text-wr .bold,
.wrap .bold {
  font-family: Gilroy;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
}

.index-text-wr .bold:hover,
.wrap .bold:hover {
  color: #de001b;
  transition: color 0.3s ease;
}

.index-text-wr .grow {
  height: 0;
  overflow: hidden;
  transition: all 0.7s ease;
}

.index-text-wr h2,
.wrap h2 {
  font-weight: 600;
  line-height: 52px;
  font-size: 52px;
  letter-spacing: 0.01em;
  color: #000;
  margin-bottom: 40px;
}

.index-text-wr p.p1,
.wrap p.p1 {
  font-size: 27px;
  margin-bottom: 30px;
}

.index-text-wr p,
.wrap p {
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.01em;
}

.indented {
  display: block;
}

.sub-text-wr {
  width: 100%;
  max-width: auto !important;
  display: flex;
  flex-wrap: wrap;
}

.sub-wrap {
  width: 100%;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
}

.mt-46 {
  margin-top: 46px;
}

.wrap {
  max-width: 730px;
  margin-right: 15px;
  width: 47%;
}
.wrap.second {
  margin-right: 0;
  margin-left: 15px;
}

.sofa-wrapper {
  max-width: 590px;
  max-height: 250px;
}

.sofa {
  width: 100%;
  height: 100%;
}

.index-text-pich {
  position: absolute;
  background: url(/img/jpg/sofa2.jpg) no-repeat left center;
  background-position: 500px;
  height: 438px;
}

.posts {
  padding-top: 35px;
  padding-bottom: 150px;
}

.title-block {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  margin-bottom: 56px;
  min-height: 92px;
}

.title-block p {
  font-weight: 600;
  line-height: 52px;
  font-size: 52px;
  letter-spacing: 0;
  color: #000;
}

.title-block p span {
  font-size: 15px;
  line-height: 40px;
  letter-spacing: 0.15em;
  color: #788390;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
}

.main-post {
  width: 620px;
}

.mainpost-picher img {
  max-width: 100%;
  width: 100%;
}

.mainpost-picher {
  margin-bottom: 30px;
}

.main-post-text a {
  line-height: 32px;
  font-size: 27px;
  color: #010101;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
}

.main-post-text p {
  letter-spacing: 0;
  font-size: 17px;
  line-height: 26px;
  margin-top: 20px;
}

.main-post:hover .main-post-text a {
  color: #d91c29;
}

.post-wr {
  overflow: hidden;
}

.post {
  margin-bottom: 70px;
  padding-right: 128px;
}

.post-data {
  font-size: 15px;
  color: #444444;
  line-height: 30px;
  letter-spacing: 0.05em;
}

.post-data::before {
  content: "";
  display: block;
  height: 1px;
  width: 40px;
  background: #788390;
  float: left;
  margin-top: 15px;
  margin-right: 12px;
  transition: background 0.3s ease;
}

.post-data-hover::before {
  background: #d91c29;
  transition: background 0.3s ease;
}

.post-name {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
  font-weight: 700;
  color: #000000;
  margin-bottom: 15px;
  margin-top: 3px;
  display: block;
}

.post-name:hover {
  color: #d91c29;
}

.post-des {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0;
}

.price-pop_up-wr {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
}

.price-pop_up-wr.closed {
  pointer-events: none;
  opacity: 0;
}

.price-pop_up-wr.closed .price-pop_up {
  transform: scale(0.9);
}

.pop_up-shadding {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.price-pop_up {
  position: relative;
  max-width: 520px;
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #cc3333;
  background-color: #fff;
}

.price-pop_up.price_up p {
  text-align: center;
}

.pop_up-close {
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.pop_up-close:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.price-pop_up h1 {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.price-pop_up .dash {
  height: 2px;
  width: 160px;
  background: #cc3333;
  margin: 15px 0 30px 0;
}
.price-pop_up p {
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.3px;
}

.price-pop_up h1 span {
  font-weight: 700;
  font-size: 28px;
}

.price-pop_up p span {
  font-weight: 700;
  color: #cc3333;
}

.pop-up__caution {
  margin-top: 20px;
  
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #cc3333;
}


.catalog__description.index-text-wr {
  width: 800px;
  max-width: 800px;
  margin: 0 20px;
}
.catalog__description h1 {
  margin-top: 86px;
  font-weight: 600;
  font-size: 52px;
  line-height: 52px;
}
.catalog__description.index-text-wr p {
  margin-bottom: 40px;
  line-height: 32px;
}
.catalog__description ul {
  margin-top: 20px;
}
.catalog__description.index-text-wr h2 {
  margin-top: 60px;
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 42px;
  line-height: 42px;
}
