@media (min-width: 1120px) {
  .detail-page {
    padding-top: 60px;
  }

  .page-title,
  .block-design-title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-title > span {
    display: none;
  }

  .favorites-list .col-3 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .filter-block {
    width: calc(100% + 40px);
    margin-right: -40px;
  }

  .filter-block.fixed {
    position: fixed;
    top: 0;
    width: calc(80% - 32px);
  }

  .filter-block.fixed + .sort-block {
    margin-top: 130px;
  }

  .sort-block {
    padding: 0 20px;
  }

  .filter-block-item {
    padding: 20px;
    height: 90px;
  }

  .filter-tipe-hide > span,
  .filter-color-hide > span,
  .filter-composition-hide > span {
    margin-top: 10px;
  }

  .filter-block-NewsOrRas label,
  .filter-block-flammability label {
    padding-bottom: 5px;
  }

  .reset-filter svg {
    margin-top: 0;
  }

  .reset-filter {
    padding-left: 10px;
    padding-right: 10px;
  }

  .filter-block-price,
  .filter-block-DryResidue,
  .filter-block-Viscosity {
    padding-left: 30px;
    padding-right: 30px;
  }

  .inputs-text span {
    font-size: 12px;
  }

  .filter-price-inputs {
    margin-bottom: 10px;
  }

  .you-show .row {
    margin-right: -10px;
    margin-left: -10px;
  }

  .col-12-8 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .you-show .row .col-12-8:nth-of-type(n + 6) {
    display: none;
  }

  /*  .design-items .col-3{
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }*/
  .title-block p {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: 0;
  }

  .title-block p span {
    font-size: 13px;
    line-height: 42px;
    letter-spacing: 0.2em;
  }

  .title-block {
    margin-bottom: 42px;
  }

  .index-text-wr {
    flex: 0 0 60%;
    max-width: 60%;
    float: none;
  }

  .index-text {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .index-text-pich {
    position: relative;
    flex: 0 0 40%;
    max-width: 40%;
    /*flex: 0 0 calc(100% - 400px);*/
    /*max-width: calc(100% - 400px);*/
    width: calc(100% - 400px);
    right: -60px;
  }

  .index-text-wr h2,
  .wrap h2 {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: 0;
  }

  .index-text-wr p.p1 {
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0;
  }

  .index-text-wr p,
  .wrap p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .news-goods {
    width: calc(100% + 40px);
    padding-right: 60px;
  }

  .news-goods-title-des p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .news-goods-title-p p {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 0;
  }

  header {
    padding-top: 30px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .catalog-block.row {
    margin-right: -20px;
  }

  .catalog-block .col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3%;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  aside ul li.active a::before {
    left: -60px;
  }

  .main-post {
    width: 100%;
  }

  .posts .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-post,
  .post-wr {
    display: flex;
    flex-wrap: wrap;
  }

  .mainpost-picher {
    flex: 0 0 66.6%;
    max-width: 66.6%;
    padding-right: 20px;
  }

  .main-post-text {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    padding-left: 20px;
  }

  .post {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 90px;
  }

  .main-post-text a {
    font-weight: 700;
    font-size: 21px;
    color: #000;
    line-height: 24px;
  }

  .post-name {
    font-size: 17px;
  }

  .post-des {
    font-size: 15px;
  }

  .post-data {
    font-size: 13px;
  }

  .posts {
    padding-bottom: 50px;
  }

  .main-post-text a::before {
    width: 40px;
    height: 1px;
    display: block;
    content: "";
    background: #000;
    margin-bottom: 25px;
  }

  .main-post-text p {
    line-height: 22px;
  }

  .page-contact-wr {
    margin-top: 30px;
    margin-right: -40px;
  }

  /*footer {*/
  /*position: absolute;*/
  /*bottom: 0;*/
  /*right: 0;*/
  /*left: 20%;*/
  /*max-width: 80%;*/
  /*margin-right: 100px;*/
  /*padding-left: 20px;*/
  /*}*/
  /*  .components-contact-title{
        padding: 0 40px;
      }*/
}

@media (min-width: 1440px) {
  .filter-block.fixed {
    width: calc(80% - 16px);
  }

  .good-text-name span {
    font-size: 13px;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 13px;
  }

  .header-contact-items {
    min-width: 320px;
  }

  header nav li a {
    font-size: 17px;
  }

  .header-contact-items p {
    font-size: 15px;
  }

  .header-contact-items p span {
    font-size: 17px;
  }

  aside > p {
    font-size: 13px;
  }

  aside ul li a {
    font-size: 17px;
  }

  h1.h1 {
    font-size: 52px;
    line-height: 52px;
  }

  .logo {
    margin-top: 15px;
  }

  .detail-page-post-pich {
    padding-top: 0;
  }

  .title-block p span {
    font-size: 15px;
    font-weight: 600;
  }

  .posts {
    padding-bottom: 110px;
  }

  .index-text-wr {
    max-width: 680px;
  }

  .index-text-pich {
    position: absolute;
    flex: 0 0 calc(100% - 570px);
    max-width: calc(100% - 570px);
    width: calc(100% - 570px);
    right: -120px;
  }

  .news-goods {
    width: calc(100% + 120px);
    padding-right: 100px;
  }

  .news-goods-title-p p {
    font-size: 52px;
  }

  .title-block p {
    font-size: 52px;
  }

  .myslider-text {
    padding-top: 235px;
  }

  .slide-title {
    width: 900px;
    font-size: 65px;
    line-height: 65px;
  }

  .slide-date {
    font-size: 15px;
  }

  .slide-description-text {
    font-size: 27px;
    width: 500px;
    line-height: 32px;
  }

  .favorites-list .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .news-page .post {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .filter-block {
    width: calc(100% + 120px);
    margin-right: -120px;
  }

  .filter-block-item {
    padding: 30px;
    height: 110px;
  }

  .reset-filter {
    padding-left: 10px;
    padding-right: 10px;
  }

  /*  .design-items .col-3{
        flex: 0 0 25%;
        max-width: 25%;
      }*/
  header {
    padding-top: 35px;
  }

  .margin-after-slider {
    margin-top: calc(87vh - 104px);
  }

  .container {
    padding-left: 20px;
    padding-right: 100px;
  }

  .catalog-block .col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: calc(25% - 1px);
  }

  .main-post {
    width: 95%;
  }

  .post-page .main-post {
    width: 50%;
  }

  .post-page .main-post-text {
    max-width: 600px;
  }

  .post-page .mainpost-picher img {
    max-width: 620px;
  }

  .mainpost-picher {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-post-text {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .post {
    padding-right: 70px;
  }

  .main-post-text a::before {
    display: none;
  }

  .main-post-text a {
    line-height: 32px;
    font-size: 27px;
    color: #010101;
    letter-spacing: 0.01em;
    margin-bottom: 30px;
  }

  .main-post-text p {
    letter-spacing: 0;
    font-size: 17px;
    line-height: 26px;
    margin-top: 20px;
  }

  .post-data {
    font-size: 15px;
    color: #444444;
    line-height: 30px;
    letter-spacing: 0.05em;
  }

  .post-name {
    font-size: 21px;
    line-height: 26px;
  }

  .post-des {
    font-size: 17px;
    line-height: 26px;
  }

  .news-goods-title-des p {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0;
  }

  .index-text-wr p,
  .wrap p {
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }

  .index-text-wr p.p1,
  .wrap p.p1 {
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .index-text-wr h2 {
    font-weight: 600;
    line-height: 56px;
    font-size: 52px;
    letter-spacing: 0.01em;
    color: #000;
    margin-bottom: 45px;
  }

  .index-text-wr .index-text-body h2 {
    font-size: 42px;
    padding-top: 70px;
  }

  .index-text-wr .index-text-body h3 {
    margin: 40px 0 20px;
  }

  .page-contact-wr {
    margin-right: -120px;
  }
}

@media (max-width: 1440px) {
  .footer-processing {
    width: 80%;
  }

  .index-text-wr .index-text-body h2 {
    margin-top: 60px;
  }
  .index-text-wr .index-text-body h3 {
    margin: 40px 0 20px;
  }
}

@media (max-width: 1250px) {
  .feedback {
    flex-direction: column;
  }
  .main_feedback {
    flex-direction: row;
  }
  .feedback .button-phone {
    margin-top: 15px;
    margin-left: 30px;
  }
  .main_feedback .button-phone {
    margin-left: 0px;
    margin-top: 0;
  }
}

@media (max-width: 1120px) {
  .search-icon {
    background-image: url(/img/svg/search_icon.svg);
  }

  .favorites-top {
    padding: 0 40px;
  }

  .favorites-list {
    margin-right: 0;
    margin-left: 0;
  }

  .favorites-panel {
    padding-left: 20px;
    padding-right: 20px;
  }

  .action-favorits {
    opacity: 1;
  }

  .detail-page-mobil {
    display: block;
  }

  .detail-page-post {
    max-width: 700px;
    margin: auto;
  }

  .container > .row > .col-xs-1-4 {
    padding-top: 80px;
  }

  .App.index-page .container > .row > .col-xs-1-4 {
    padding-top: 0;
  }

  /*  .news-components-title{
        padding-left: 20px;
        padding-right: 20px;
      }*/
  .filter-block {
    padding-left: 20px;
  }

  .filter-block-item {
    padding: 20px;
    max-height: 100px;
  }

  .filter-block-DryResidue .inputs-wr input {
    width: 60px;
  }

  .glue-item {
    padding-left: 0;
    padding-right: 0;
  }

  .filter-block-flammability label span {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .filter-block-flammability label p {
    line-height: 21px;
  }

  .filter-block-price,
  .filter-block-DryResidue,
  .filter-block-Viscosity {
    max-width: inherit;
  }

  .mb-like-title p {
    font-size: 27px;
  }

  .more-text {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .colection-parametr-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .about-price,
  .colection-parametr-item .col-6 p,
  .collection-props p,
  .more-text p {
    font-size: 13px;
  }

  .colection-title-price p.price,
  .colection-title-price p.priceSale,
  .you-show-title p {
    font-size: 21px;
    margin-top: 20px;
  }

  .type-status,
  .colection-description > p {
    font-size: 15px;
  }

  h1.colection-name {
    font-size: 44px;
    line-height: 44px;
  }

  .block-design-title-text {
    width: 50%;
  }

  /*  .design-items .col-3{
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }*/
  .col-12-8 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .you-show-title {
    padding-right: 20px;
    padding-left: 20px;
  }

  .you-show .row .col-12-8:nth-of-type(n + 6) {
    display: none;
  }

  .mb-like {
    margin-left: 0;
    margin-right: 0;
  }

  .you-show .row {
    margin-left: 0;
    margin-right: 0;
  }

  .block-design-title-text {
    margin-right: 20px;
  }

  .glue-item .col-4 {
    padding-left: 20px;
  }

  .glue-item .col-8 {
    padding-right: 20px;
  }

  .footer-logo-imm img {
    display: block;
    width: 100%;
  }

  .mobile-header {
    display: block;
  }

  .mobile-header a {
    float: left;
    padding: 0 15px;
    margin-left: -15px;
    line-height: 60px;
  }

  .mobile-header a > img {
    width: 202px;
  }

  .button-menu {
    float: left;
    padding: 22px 15px 12px 15px;
    cursor: pointer;
  }

  .button-menu svg {
    width: 28px;
    height: 21px;
  }

  .button-menu.active path:first-child {
    transform: rotate(45deg) translateX(3px) translateY(-3px);
  }

  .button-menu.active path:nth-of-type(2) {
    display: none;
  }

  .button-menu.active path:last-child {
    transform: rotate(-45deg) translateX(-12px) translateY(0px);
  }

  .button-menu:hover svg path {
    fill: #d91c29;
    transition: 0.2s all ease-in-out;
  }

  .button-menu svg path {
    fill: #788390;
    transition: 0.3s all ease-in-out;
  }

  .mobile-header > .button-mail {
    float: left;
    margin-top: 3px;
    margin-left: 48px;
    padding: 0 15px;
    line-height: 57px;
    color: #788390;
  }

  .button-phone {
    float: right;
    margin-top: 3px;
    margin-right: -15px;
    padding: 17px 15px;
  }
  .button-city {
    float: right;
    margin-top: 3px;
    margin-right: -15px;
    padding: 17px 15px;
  }

  .container > .row > .col-xs-1-5 {
    display: none;
  }

  .container > .row > .col-xs-1-4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    padding: 0;
  }

  header nav {
    display: none;
  }

  .index-scroll {
    top: 500px;
  }

  .myslider-text {
    padding: 137px 40px 0 40px;
  }

  .slide img {
    min-height: auto;
    min-width: 100%;
  }

  .margin-after-slider {
    margin-top: 680px;
  }

  .catalog-block.row {
    padding: 0 20px;
  }

  .catalog-block .col-3 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .index-text-wr {
    flex: 0 0 66.6%;
    max-width: 66.6%;
    float: none;
  }

  .index-text {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .index-text-pich {
    position: relative;
    flex: 0 0 33.3%;
    max-width: 33.3%;
    right: -20px;
  }

  .main-post {
    width: 100%;
  }

  .posts .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-post,
  .post-wr {
    display: flex;
    flex-wrap: wrap;
  }

  .mainpost-picher {
    flex: 0 0 66.6%;
    max-width: 66.6%;
    padding-right: 20px;
  }

  .main-post-text {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    padding-left: 20px;
  }

  .post {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 90px;
  }

  .main-post-text a {
    font-weight: 700;
    font-size: 21px;
    color: #000;
    line-height: 24px;
  }

  .post-name {
    font-size: 17px;
  }

  .post-des {
    font-size: 15px;
  }

  .post-data {
    font-size: 13px;
  }

  .posts {
    padding-bottom: 50px;
  }

  .main-post-text a::before {
    width: 40px;
    height: 1px;
    display: block;
    content: "";
    background: #000;
    margin-bottom: 25px;
  }

  .main-post-text p {
    line-height: 22px;
  }

  .news-goods-title-des p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .news-goods-title-p p {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 0;
  }

  .title-block p {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: 0;
  }

  .title-block p span {
    font-size: 13px;
    line-height: 42px;
    letter-spacing: 0.2em;
  }

  .title-block {
    margin-bottom: 42px;
  }

  .index-text-wr h2,
  .wrap h2 {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: 0;
  }

  .index-text-wr p.p1 {
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0;
  }

  .index-text-wr p,
  .wrap p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .news-goods-title-des p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .news-goods-title-p p {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 0;
  }

  .index-text-wr {
    height: auto;
  }

  .index-text-pich {
    height: 310px;
    background-size: cover;
  }

  .sub-wrap {
    margin-top: 30px;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding-top: 0;
    padding-bottom: 0;
    background: #0b1927;
    z-index: 11;
  }

  header .col-2 {
    display: none;
  }

  header .col-5:nth-of-type(3) {
    display: none;
  }

  header .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 40px;
  }

  header .container:nth-of-type(2) {
    display: none;
  }

  .footer-biz-logo {
    padding-left: 15px;
  }

  .search-icon {
    padding: 21.5px 10px;
    box-sizing: content-box;
    height: 17px;
    float: left;
    background-position: center;
    background-size: 17px;
  }
  .block-design-title {
    display: block;
  }
  .block-design-title-left {
    float: none;
  }
  .block-design-description {
    width: 100%;
    padding-left: 20px;
    margin-top: 10px;
  }
  .colection-parametr {
    margin-right: 30px;
  }
  .feedback {
    width: auto;
  }
  .feedback .button-phone {
    padding: 0;
  }

  .main_feedback {
    width: 300px;
  }

  .catalog__description.index-text-wr {
    max-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .favorites-list .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .item-ab p.item-name {
    margin-bottom: 10px;
  }

  .item-ab p.item-street {
    margin-bottom: 10px;
  }

  .item-ab p.item-phone {
    margin-bottom: 10px;
    line-height: 21px;
  }

  .acce-page-top-img {
    display: none;
  }

  .aces-page-top-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .glue-item {
    padding-left: 0;
    padding-right: 0;
  }

  .reset-filter svg {
    margin-top: 3px;
  }

  .filter-block {
    height: 100px;
  }

  .slide-title {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0;
  }

  .slide-date {
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 0.05em;
  }

  .index-scroll {
    display: none;
  }

  .margin-after-slider {
    margin-top: 87vh;
  }

  .index-text-pich {
    background: url(/img/jpg/sofa2.jpg) no-repeat right center;
    background-size: cover;
  }

  .catalog-block .col-3 {
    flex: 0 0 50%;
    max-width: calc(50% - 1px);
  }

  .index-text-wr {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .index-text-pich {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .index-text-pich {
    height: 400px;
    top: 0 !important;
  }

  .index-text-wr p.p1 {
    font-size: 17px;
    line-height: 21px;
  }

  .index-text-wr p {
    font-size: 15px;
    line-height: 21px;
  }

  .posts {
    padding-top: 0px;
  }

  .mainpost-picher {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-post-text {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .post {
    padding-right: 20px;
  }

  .title-block {
    min-height: auto;
  }

  .posts .col-6:nth-of-type(2) {
    padding-top: 20px;
  }

  .main-post-text a,
  .main-post-text p {
    font-size: 17px;
    line-height: 21px;
  }

  .post-name,
  .post-des {
    line-height: 21px;
  }

  .post {
    margin-bottom: 0;
  }

  .goods-text p {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
  }

  .myslider-item {
    background-size: 100%;
  }

  .catalog-block.row {
    padding-top: 30px;
  }

  .favorites-top p {
    width: 100%;
  }

  .favorites-panel-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .favorites-panel-right {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .favorites-panel-title {
    font-size: 16px;
  }

  .favorites-panel-title p {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 170px;
    overflow: hidden;
  }

  .contact-form {
    display: none;
  }

  /*  .components-contact-title{
        padding: 0 20px;
      }*/
  .page-contact-wr .item .item-name {
    color: black;
    padding: 21px 20px;
    background: url(/img/svg/ + .svg) no-repeat center right 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .page-contact-wr .item.active .item-name {
    color: #d91c29;
    background: url(/img/svg/-.svg) no-repeat center right 20px;
  }

  .page-contact-wr .item .item-ab {
    padding: 0 20px;
    max-height: 60px;
    transition: 0.8s ease;
  }

  .page-contact-wr .item.active .item-ab {
    max-height: 1000px;
  }

  .page-contact-wr .item.active .item-ab {
    display: block;
  }

  .page-contact-wr .item.active .item-ab-center {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .page-contact-wr .item .mobil-map {
    display: block;
    width: calc(100% + 40px);
    height: 250px;
    margin: 0 -20px;
    padding-top: 30px;
  }

  .detail-page-post .description-text {
    font-size: 21px;
    line-height: 28px;
  }

  .detail-text p {
    font-size: 17px;
    line-height: 26px;
  }

  .detail-page-post-pich {
    padding-top: 0;
  }

  .detail-page-post .description-text {
    padding-bottom: 30px;
  }

  .detail-text p.title {
    font-size: 27px;
    line-height: 26px;
  }

  .page-contact-wr .item {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
  }

  .page-contact-wr .item.active {
    border: 0;
    border-bottom: 1px solid #ccc;
  }

  .page-contact-wr .item:last-child {
    border-bottom: unset;
  }

  .map {
    display: none;
  }

  .detail-page-post {
    max-width: 90%;
  }

  .detail-text-img {
    padding-top: 20px;
  }

  .detail-text-img p.alt2 {
    font-size: 21px;
    line-height: 28px;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .detail-text ul p {
    font-size: 21px;
    line-height: 26px;
  }

  .detail-text ul li {
    font-size: 17px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 0;
  }

  .avtor-name {
    font-size: 17px;
  }

  .avtor-info {
    font-size: 15px;
  }

  .glue-page .colection-description {
    display: none;
  }

  .breadcrumbs {
    display: block;
  }

  .design-item-picher img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }

  .slider-icon,
  .colection-title {
    display: none;
  }

  .colection-description .more-text {
    display: none;
  }

  .type-status,
  .colection-description > p {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .type-status {
    padding-left: 0;
  }

  .block-design-title-text {
    padding-left: 40px;
  }

  .slider-icon.slider-icon-mobil {
    padding-top: 20px;
  }

  .slider-icon-item.col-6 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  /*  .design-items .col-3{
        flex:0 0 50%;
        max-width: 50%;
      }*/
  .colection-parametr,
  .colection-description {
    float: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .feedback {
    flex-direction: row;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-top: 0;
  }

  .feedback .button-mail {
    margin-right: 25px;
  }

  .main_feedback .button-mail {
    margin-right: 55px;
  }

  .feedback__title {
    padding-top: 0;
    text-align: center;
  }

  .feedback .button-phone {
    width: auto;
  }

  .slider-icon.slider-icon-mobil {
    display: flex;
  }

  .detail-page-top .col-8,
  .detail-page-top .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .colection-title-price p.priceSale,
  .colection-title-price p.price {
    margin-top: 45px;
  }

  .type-status a {
    color: #6c7580;
  }

  .type-status,
  .colection-description > p {
    font-size: 13px;
  }

  .about-price {
    margin-top: 0;
  }

  h1.colection-name {
    margin-bottom: 0;
    font-size: 42px;
  }

  .detail-page-mobil-title {
    display: flex;
    padding-bottom: 20px;
    margin-right: -20px;
    margin-left: -20px;
  }

  .glue-item .basis {
    font-size: 12px;
  }

  .filter-block.hide {
    height: 0;
    opacity: 0;
  }

  .filter-mobil-sort span {
    margin-right: 20px;
  }

  .sort-block {
    display: none;
  }

  .favorites-fix {
    bottom: 100px;
  }

  .filter-mobil-panel {
    display: block;
  }

  .filter-block-item .list-span {
    display: none;
  }

  .popup-block {
    max-height: unset;
    top: 0;
    padding: 20px;
    overflow-y: scroll;
  }

  .popup-block.message-active {
    max-height: 100%;
  }

  .popup-block.active .contact-form-tab {
    width: unset;
    height: unset;
  }

  .popup-block.message-active .contact-form-tab {
    width: unset;
    height: unset;
  }

  .popup-block.active .contact-form-tab .item-tab {
    width: unset;
    float: unset;
    margin-bottom: 5px;
  }

  .popup-block.message-active .contact-form-tab .item-tab {
    width: unset;
    float: unset;
    margin-bottom: 5px;
  }

  .popup-block-title {
    text-align: unset;
  }

  .popup-block .input-wr,
  .popup-block .input-wr .input {
    width: 100%;
  }

  .filter-block {
    height: 0px;
    padding-left: 0;
    overflow: hidden;
  }

  .filter-block.show {
    height: auto;
  }

  /*.filter-block.show.hide {*/
  /*height: 0;*/
  /*overflow: hidden;*/
  /*}*/
  .filter-block-item {
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 2px solid #c8ced6;
    padding: 20px 40px;
    max-height: initial;
  }

  .reset-filter {
    display: none;
  }

  .filter-tipe-show,
  .filter-color-show,
  .filter-tags-show,
  .filter-composition-show {
    position: static;
    width: 100%;
    height: auto;
    display: none;
    padding: 0;
    background: rgba(0, 0, 0, 0);
  }

  .filter-block-item.is-active .opened-block {
    display: block;
  }

  .filter-tipe-show > p,
  .filter-color-show > p,
  .filter-tags-show > p,
  .filter-composition-show > p {
    display: none;
  }

  .filter-tipe-checkbox label,
  .filter-tags-checkbox label,
  .filter-color-radio label,
  .filter-block-NewsOrRas label,
  .filter-composition-checkbox label,
  .filter-block-flammability label {
    width: 33.3%;
    float: left;
    overflow: hidden;
  }

  .filter-block-NewsOrRas label {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .filter-block-item:last-child {
    border-bottom: 0;
  }

  .filter-tipe-hide > span,
  .filter-color-hide > span,
  .filter-composition-hide > span {
    display: none;
  }

  .myslider-text {
    padding-top: 100px;
  }

  .slide-title {
    font-size: 27px;
    line-height: 30px;
    width: 100%;
  }

  .news-goods-title .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .slide img {
    height: 450px;
  }

  .slide .block-shadow {
    width: 100%;
    background: rgba(11, 24, 28, 0.2);
  }

  .news-goods-title {
    padding-top: 40px;
  }

  .news-goods-title-p p {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .news-goods-title-des p {
    line-height: 21px;
  }

  .index-text-wr {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .wrap.second {
    margin-right: 0;
    margin-left: 0;
  }

  .index-text-pich {
    flex: 0 0 100%;
    max-width: 100%;
    height: 300px;
    right: -20px;
    margin: 20px 0;
    background-size: cover;
  }

  .main-post-text {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .index-text-wr h2 {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: 0;
  }

  .index-text {
    padding-top: 40px;
  }

  .mainpost-picher {
    flex: 0 0 100vw;
    max-width: 100vw;
    margin-bottom: 20px;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: -20px;
  }

  .title-block p {
    font-size: 34px;
    line-height: 40px;
  }

  .title-block p span {
    font-size: 11px;
    line-height: 40px;
  }

  .main-post-text a::before {
    display: none;
  }

  .posts .col-6:nth-of-type(2) {
    padding-top: 40px;
  }

  .posts .col-6:nth-of-type(2) .title-block {
    margin-bottom: 20px;
  }

  .post {
    flex: 0 0 100vw;
    max-width: 100vw;
    margin-bottom: 40px;
  }

  .post:last-child {
    margin-bottom: 0;
  }

  footer {
    padding: 20px;
    background-color: #f2f3f5;
  }

  footer .row {
    display: none;
  }

  footer .footer-mobil.row {
    display: block;
    text-align: center;
  }

  footer .footer-mobil .col-12 {
    padding: 12px 0;
  }

  .footer-mobil-items {
    display: flex;
    flex-wrap: wrap;
  }

  .footer-mobil-items .span1 {
    font-size: 10px;
    color: #788390;
    text-align: right;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    flex: 0 0 50%;
    display: block;
    max-width: 50%;
    padding-right: 10px;
  }

  .footer-mobil-items .span2 {
    font-size: 15px;
    color: #000;
    letter-spacing: 0.01em;
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: left;
    font-weight: 600;
  }

  .footer-coo,
  .footer-biz {
    text-align: center;
  }

  .footer-biz span {
    text-align: left;
    padding-left: 30px;
  }

  .mobile-header > a > img {
    width: 150px;
  }

  .slide-description-text {
    width: 100%;
  }

  .slide-date {
    margin-bottom: 0;
  }

  .mobile-menu .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mobile-menu-one {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .mobile-menu-two {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .mobile-menu .row {
    padding-bottom: 0;
  }

  .margin-after-slider {
    margin-top: calc(568px - 15vh);
  }

  .inputs-wr input {
    width: 60px;
    height: 30px;
  }

  .inputs-wr {
    float: none;
    display: block;
    width: 100%;
  }

  .inputs-text {
    float: none;
    width: 100%;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .filter-price-skider {
    margin-bottom: 10px;
  }

  .inputs-wr input {
    background: #fff;
    border: 1px solid #cac9c9;
  }

  .inputs-wr span {
    line-height: 30px;
  }

  .inputs-wr span.money-rus {
    line-height: 30px;
    width: 40px;
    text-align: center;
  }

  .post-page-wr {
    padding-left: 0;
    padding-right: 0;
  }

  .post-page .main-post-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .loader {
    width: 100%;
  }

  .inner-sub-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .inner-sub-wrapper h2 {
    margin-top: 0 !important;
  }
}

@media (max-width: 600px) {
  .mobile-header > .button-mail {
    width: 40px;
    height: 60px;
    padding: 0 8px;
    margin-left: 0;
  }

  .button-phone {
    width: 40px;
    padding: 17px 11px;
  }

  .button-city {
    width: 40px;
    padding: 17px 11px;
  }

  .mobile-menu-two-mess {
    display: block;
  }

  .collection-props .prop-descr-modal {
    left: 0;
    width: 200%;
    max-width: unset;
  }

  .collection-props .prop-descr-modal.right-col {
    left: -100%;
  }
}

@media screen and (min-width: 715px) and (max-width: 768px) {
  .index-text-pich {
    background-size: contain;
  }
}

@media (max-width: 500px) {
  .collection-props .prop-descr-modal {
    width: 100%;
  }

  .collection-props .prop-descr-modal.right-col {
    left: 0;
  }

  .aces-page-top-table {
    overflow-x: scroll;
  }

  .myslider-text {
    padding: 100px 20px 0 20px;
  }

  .header .col-5,
  .page-title,
  .block-design-title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .detail-page-top {
    padding: 0;
  }

  .filter-mobil-sort {
    height: unset;
    padding-left: 20px;
    line-height: 30px;
  }

  .filter-mobil-sort p {
    display: flex;
    flex-direction: column;
  }

  .filter-block-item {
    padding: 20px;
  }

  .favorites-panel.row {
    padding: 0 10px;
  }

  .favorites-panel-left {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .favorites-panel-right {
    flex: 0 0 10px;
    max-width: 10px;
  }

  .favorites-panel-category {
    display: none;
  }

  .favorites-list .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .detail-page-post {
    max-width: 100%;
  }

  h1.h1 {
    font-size: 27px;
    line-height: 40px;
  }

  h1.h1 br {
    display: none;
  }

  .detail-page-post-pich p.alt {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .detail-text p {
    line-height: 21px;
  }

  .detail-text p.title {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .detail-text-img {
    margin-left: -20px;
    margin-right: -20px;
  }

  .detail-text-img p.alt2 {
    width: 70%;
    margin: auto;
    font-size: 17px;
    line-height: 21px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .detail-text ul p {
    font-size: 17px;
    line-height: 21px;
  }

  .detail-text ul {
    margin-top: 40px;
  }

  .detail-text ul li {
    font-size: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    padding-left: 20px;
  }

  .avtor-name {
    font-size: 15px;
    line-height: 18px;
  }

  .avtor-info {
    font-size: 13px;
    line-height: 18px;
  }

  .avtor-text {
    width: 100px;
  }

  .detail-text ul li::before {
    position: absolute;
    left: 0;
  }

  .detail-text-img img {
    width: 100%;
  }

  .detail-text p {
    margin-bottom: 15px;
  }

  .detail-page-post .description-text {
    font-size: 17px;
    line-height: 21px;
  }

  .detail-text p {
    font-size: 15px;
  }

  .detail-page-post-content {
    overflow: visible;
  }

  .detail-page-post-pich {
    margin-left: -20px;
    margin-right: -20px;
  }

  .glue-page .colection-description {
    display: block;
  }

  .glue-item .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 20px;
  }

  .glue-page .colection-description {
    margin-top: 0;
  }

  .glue-item .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .filter-tipe-checkbox label,
  .filter-tags-checkbox label,
  .filter-color-radio label,
  .filter-block-NewsOrRas label,
  .filter-composition-checkbox label,
  .filter-block-flammability label {
    width: 50%;
  }

  .catalog-block.row {
    padding: 20px 0 0 0;
  }

  .catalog-block .col-3,
  .collection-props .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*.filter-mobil-sort a {*/
  /*display: none;*/
  /*}*/
  .filter-mobil-sort a:nth-of-type(2) {
    display: block;
    float: left;
  }

  .filter-mobil-sort span {
    float: left;
  }

  .detail-page-mobil-title .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .detail-page-mobil-title .col-6:nth-of-type(2) {
    display: none;
  }

  .detail-page-mobil-title {
    padding-bottom: 15px;
  }

  .slider-bottom .item {
    width: 50px;
    height: 50px;
    overflow: hidden;
  }

  .slider-bottom .item img {
    max-width: 100%;
  }

  .slider-bottom .item.swiper-slide-active::before {
    border-width: 2px;
  }

  .slider-bottom .item.swiper-slide-active img {
    max-width: 100%;
  }

  .colection-parametr,
  .colection-description {
    max-width: 100%;
    float: none;
  }

  .colection-description > p {
    padding-left: 0;
    padding-right: 0;
  }

  .colection-description .more-text {
    border-top: 1px solid #c9cdd3;
    padding-top: 19px;
    padding-bottom: 19px;
    display: block;
  }

  .more-text p {
    line-height: 20px;
  }

  .more-text span {
    margin-right: 20px;
  }

  .slider-icon-item.col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .colection-description {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .slider-icon-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  /*  .design-items .col-3{
        flex: 0 0 100%;
        max-width: 100%;
      }*/
  .block-design-title {
    padding-bottom: 20px;
  }

  .block-design-title h4 {
    font-size: 21px;
  }

  .block-design-title-text {
    float: none;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .block-design-title-left {
    float: none;
    width: 100%;
  }

  .block-design-title-left h4 {
    font-size: 21px;
  }

  .mb-like-title p {
    font-size: 21px;
  }

  .mb-like-title {
    padding-top: 10px;
    margin-bottom: 20px;
  }

  .sw-pag {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .design-item-picher img {
    margin-top: 20px;
  }

  .mb-like-slider .col-3 {
    padding-left: 0;
    padding-right: 0;
  }

  .mb-like-title p {
    padding-left: 0;
    padding-right: 0;
  }

  .you-show-title {
    padding-left: 0;
    padding-right: 0;
  }

  .you-show-title p {
    margin-top: 0;
  }

  .sw-pag {
    padding-top: 10px;
  }

  .block-design.adition-design {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobil-hide-block {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.3s all ease-in-out;
  }

  .mobil-hide-block.active {
    height: inherit;
    opacity: 1;
    padding-top: 30px;
  }

  .news-goods-wrap {
    margin: 0 -20px;
    width: calc(100% + 40px);
  }

  .news-goods-wrap .swiper-slide .col-3 {
    /*    padding-left: 0;
            padding-right: 0;*/
  }

  .news-goods-title .col-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .acce-page-top-img {
    display: block;
    width: 100%;
    float: none;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }

  .goods-wr {
    padding-bottom: 0;
  }

  .filter-block-NewsOrRas label,
  .filter-block-flammability label {
    padding-top: 10px;
    margin-bottom: 0;
  }

  .news-goods {
    padding-top: 0;
  }

  .acce-page-color {
    width: 310px;
    margin: auto;
  }

  .favorites-list.row {
    padding: 0;
  }

  .favorites-top {
    padding: 0 20px;
  }

  .page-contact-wr > .row {
    display: block;
    min-height: inherit;
    padding-top: 20px;
  }
  .feedback {
    width: 290px;
  }
  .feedback .button-mail {
    margin-right: 10px;
  }
  .main_feedback .button-mail {
    /* margin-right: 55px; */
  }
  .feedback .button-phone {
    width: auto;
  }
  .main_feedback .button-phone {
    margin-left: 30px;
  }
  .catalog__description.index-text-wr {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .price-pop_up {
    width: 320px;
    height: 450px;
  }

  .price-pop_up .dash {
    height: 2px;
    width: 120px;
    margin: 0 0 20px 0;
  }

  .price-pop_up p {
    font-size: 20px;
    line-height: 30px;
  }

  .price-pop_up h1 span {
    font-size: 25px;
  }
  .mb-20-mobile {
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .mobile-header > a > img {
    width: 112px;
  }

  .button-menu {
    padding: 22px 10px 12px 10px;
  }
}

@media (max-width: 350px) {
  .mobile-header a {
    padding: 0px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .myslider-item,
  .muslider-item .active {
    background-size: 270%;
    background-position-x: 30%;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) and (orientation: landscape) {
  .myslider-item,
  .muslider-item .active {
    background-size: 100%;
    background-position-x: 30%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 320px) {
  .margin-after-slider {
    margin-top: 87vh;
  }
  .collection-props .prop-descr-modal,
  .collection-props .prop-descr-modal.right-col {
    left: -10px;
  }

  .search-icon {
    padding: 21.5px 10px;
  }
}

@media only screen and (min-width: 320px) and (max-height: 480px) and (orientation: landscape) {
  .margin-after-slider {
    margin-top: 85vh;
  }
}

@media screen and (width: 568px) and (height: 320px) {
  .myslider-container {
    height: 110vh;
  }

  .margin-after-slider {
    margin-top: 110vh;
  }
}

@media screen and (min-width: 640px) and (max-width: 736px) and (orientation: landscape) {
  .myslider-container {
    height: 100vh;
  }

  .margin-after-slider {
    margin-top: 100vh;
  }
}

@media screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
  .myslider-container {
    height: 35vh;
  }

  .margin-after-slider {
    margin-top: 35vh;
  }
}

@media screen and (width: 1024px) and (height: 768px) and (orientation: landscape) {
  .myslider-container {
    height: 87vh;
  }

  .margin-after-slider {
    margin-top: 87vh;
  }
}

@media (max-height: 700px) and (min-width: 1000px) {
  /* TO DO */
  .myslider-container {
    min-height: 700px;
  }

  .margin-after-slider {
    margin-top: 601px;
  }
}

@media (max-height: 720px) {
  .search .search-field {
    margin-top: 10px;
  }
}
